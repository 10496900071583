<template lang="pug">
  .page-container
    register-steps(type="customer")
    task-price
    protect(title="Гарантия качества работы и сохранения ваших средств", body="Мы гарантируем, что работа будет выполнена, а ваши средства будут в безопасности, пока вы не решите выплатить их исполнителю, а если качество работы вас не удовлетворит - то платить не обязательно!")
    testimonials
    best-freelancers
</template>

<script>
import RegisterSteps from "../components/app-common/register-steps/register-steps";
import TaskPrice from "../components/app-common/task-price/task-price";
import Protect from "../components/app-common/protect/protect";
import Testimonials from "../components/home-page/testimonials/testimonials";
import BestFreelancers from "../components/home-page/best/best";
export default {
  name: "Customer",
  components: {
    BestFreelancers,
    Testimonials,
    Protect,
    TaskPrice,
    RegisterSteps
  }
};
</script>
