import API from "@/API";

export default class UsersModel extends API {
  create(userData) {
    return this._post("/auth/register", userData);
  }
  getOneById(id) {
    return this._get(`/users/${id}`);
  }
  getMyData() {
    return this._get("/users/my", {}, true);
  }
  saveMyData(data) {
    return this._post("/users/my/save", data, true);
  }
  getReferrals() {
    return this._get("/users/referrals", {}, true);
  }
}
