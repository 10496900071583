<template lang="pug">
  form(@submit.prevent="addFeedback" v-if="!info").add-feedback
    ._title Добавить отклик к заказу
    ._row
      ._group
        label(for="budget")._label Бюджет задачи
        ._price-group
          input(type="text" v-model="budget" @keydown="checkNumbers")#budget._input
          ._price-symbol ₽
          ._error(v-if="$v.budget.$error") Цена не может быть ниже 100 ₽
      ._group
        label._label Срок на разработку
          span._any-ctrl(@click="toggleDeadlineStatus") {{ deadlineStatusText }}
        ._ctrl-group
          input(type="text" v-model="deadline" @keydown="checkNumbers" :class="{full: deadline === 'По договорённости'}")._input#deadline
          app-select(:currentValue="deadlineActive" :list="deadlineTypes" model="deadlineActive" @changeModel="changeModel" v-if="deadline !== 'По договорённости'")
        ._error(v-if="$v.deadline.$error && deadlineActive !== 'По договорённости'") Срок разработки должен быть больше 0
    ._row
      ._group.-full 
        label(for="text")._label Подробно опишите вашу задачу
        textarea#text(v-model="description")._input.-textarea.
        ._error(v-if="$v.description.$error") Описание задачи не может быть короче {{ $v.description.$params.minLength.min }} символов
    ._row.-footer
      button(type="submit").btn.-blue Добавить отклик       
  app-info(v-else) {{ info }}    
</template>

<script>
import declinationOfNumber from "@/filters/declination.filter";
import { required, minLength, between } from "vuelidate/lib/validators";
import AppSelect from "@/components/app-common/app-select/app-select";
import projectsFeedbackModel from "@/models/projectsFeedback.model";
const PROJECTS_FEEDBACK = new projectsFeedbackModel();

export default {
  name: "add-feedback",
  components: { AppSelect },
  props: {
    projectId: {
      required: true,
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      info: null,
      deadline: null,
      budget: null,
      deadlineActive: "День",
      description: null
    };
  },
  computed: {
    feedbackData: state => ({
      deadline: state.deadline + " " + state.deadlineActive,
      budget: state.budget,
      description: state.description,
      project_id: state.projectId
    }),
    deadlineTypes: state => [
      {
        id: 1,
        name: (function() {
          return declinationOfNumber(state.deadline, ["Час", "Часа", "Часов"]);
        })()
      },
      {
        id: 2,
        name: (function() {
          return declinationOfNumber(state.deadline, ["День", "Дня", "Дней"]);
        })()
      },
      {
        id: 3,
        name: (function() {
          return declinationOfNumber(state.deadline, [
            "Неделя",
            "Недели",
            "Недель"
          ]);
        })()
      },
      {
        id: 4,
        name: (function() {
          return declinationOfNumber(state.deadline, [
            "Месяц",
            "Месяца",
            "Месяцев"
          ]);
        })()
      }
    ],
    deadlineStatusText: state =>
      state.deadline === "По договорённости"
        ? "Указать срок"
        : "По договорённости"
  },
  validations: {
    description: {
      required,
      minLength: minLength(50)
    },
    budget: {
      required,
      between: between(100, 1000000)
    },
    deadline: {
      required
    }
  },
  methods: {
    toggleDeadlineStatus() {
      if (this.deadline === "По договорённости") {
        this.deadline = null;
      } else {
        this.deadline = "По договорённости";
      }
    },
    changeModel(value, model) {
      this[model] = value;
    },
    checkNumbers(e) {
      const regExp = /\d/;
      if (!regExp.test(e.key) && e.key !== "Backspace" && e.key !== "Delete")
        return e.preventDefault();
    },
    async addFeedback() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      this.loading = true;
      await PROJECTS_FEEDBACK.create(this.feedbackData)
        .then(r => {
          this.info = r.data.message;
          setTimeout(() => {
            console.log("emit");
            this.$emit("newFeedback");
          }, 5000);
        })
        .catch(e => {
          console.log(e);
        });
    }
  },
  watch: {
    deadline() {
      if (this.deadlineActive[0] === "Ч") {
        this.deadlineActive = declinationOfNumber(this.deadline, [
          "Час",
          "Часа",
          "Часов"
        ]);
      }
      if (this.deadlineActive[0] === "Д") {
        this.deadlineActive = declinationOfNumber(this.deadline, [
          "День",
          "Дня",
          "Дней"
        ]);
      }
      if (this.deadlineActive[0] === "Н") {
        this.deadlineActive = declinationOfNumber(this.deadline, [
          "Неделя",
          "Недели",
          "Недель"
        ]);
      }
      if (this.deadlineActive[0] === "М") {
        this.deadlineActive = declinationOfNumber(this.deadline, [
          "Месяц",
          "Месяца",
          "Месяцев"
        ]);
      }
    }
  }
};
</script>

<style src="./add-feedback.scss" lang="scss" scoped></style>
