<template lang="pug">
  .app-nav.section
    ._wrap.wrap
        nav._container
            ._list
                ._item
                  router-link(:to="{name: 'About'}" tag="a" active-class="active")._link О сервисе
                ._item
                  router-link(:to="{name: 'Freelancers'}" tag="a" active-class="active")._link Фрилансеры
                ._item
                  router-link(:to="{name: 'Projects'}" tag="a" active-class="active")._link Работа
                ._item.-not-mobile
                    router-link(:to="{name: 'Customer'}" tag="a" active-class="active")._link Заказчикам
                ._item.-not-mobile
                  router-link(:to="{name: 'Freelancer'}" tag="a" active-class="active")._link Исполнителям
                ._item.-not-mobile
                  router-link(:to="{name: 'News'}" tag="a")._link Новости
</template>

<script>
export default {
  name: "app-nav"
};
</script>

<style lang="scss" scoped>
@import "app-nav";
</style>
