<template lang="pug">
section.create-order.section
  app-loading(v-if="loading")
  ._wrap.wrap
    template(v-if="!success")
      .title._title.-lg Создание нового заказа:
      ._form
        ._row
          ._group
            label(for="title")._label Название заказа
            input(type="text" v-model="title")#title._input
            ._error(v-if="$v.title.$error") Заголовок не может быть короче {{ $v.title.$params.minLength.min }} символов
      
          ._group
            ._input-wrap
              label._label Категория
              app-select(:currentValue="category" :list="categories" model="category" @changeModel="changeModel")
              ._error(v-if="$v.category.$error") Выберите категорию задачи
        ._row
          ._group.-full 
            label(for="text")._label Подробно опишите вашу задачу
            textarea#text(v-model="description")._input.-textarea.
            ._error(v-if="$v.description.$error") Описание задачи не может быть короче {{ $v.description.$params.minLength.min }} символов
        ._row
          ._group
            label(for="budget")._label Бюджет задачи
            ._price-group
              input(type="text" v-model="budget" @keydown="checkNumbers")#budget._input
              ._price-symbol ₽
              ._error(v-if="$v.budget.$error") Цена не может быть ниже 100 ₽
          ._group
            label._label Срок на разработку
              span._any-ctrl(@click="toggleDeadlineStatus") {{ deadlineStatusText }}
            ._ctrl-group
              input(type="text" v-model="deadline" @keydown="checkNumbers" :class="{full: deadline === 'По договорённости'}")._input#deadline
              app-select(:currentValue="deadlineActive" :list="deadlineTypes" model="deadlineActive" @changeModel="changeModel" v-if="deadline !== 'По договорённости'")
            ._error(v-if="$v.deadline.$error && deadlineActive !== 'По договорённости'") Срок разработки должен быть больше 0
        //._row.-offset-30
        //  ._group.-full
        //    ._grout-title Выберите файлы...
        //    label(for="file")._label.-file.-mb-0 (doc, docx, pptx, pdf, zip)
        //      input(type="file")._input.-file#file
        //      span._file-help Выбрать файл
      
        button(type="button" @click="createOrder").btn.-blue-darken._send.-full Создать заказ
    appSuccess(v-else title="Ваша задача успешно создана!" btnText="Посмотреть заказ" :to="{name: 'Project', params: {id: projectId}}")
</template>

<script>
import declinationOfNumber from "@/filters/declination.filter";
import { required, minLength, between } from "vuelidate/lib/validators";
import AppSelect from "../app-common/app-select/app-select";
import AppLoading from "../app-common/app-loading/app-loading";
import appSuccess from "../app-common/app-success/app-success";
import ProjectsModel from "@/models/projects.model";

const PROJECTS = new ProjectsModel();

const notDefaultCategory = function(value) {
  return value !== "Выберите категорию";
};

export default {
  name: "create-order",
  components: { AppSelect, AppLoading, appSuccess },
  data() {
    return {
      loading: false,
      title: null,
      category: "Выберите категорию",
      description: null,
      budget: null,
      deadline: null,
      deadlineActive: "День",
      dropdownActive: 1,
      projectId: 0,
      success: false
    };
  },
  validations: {
    title: {
      required,
      minLength: minLength(10)
    },
    category: {
      required,
      notDefaultCategory
    },
    description: {
      required,
      minLength: minLength(50)
    },
    budget: {
      required,
      between: between(100, 1000000)
    },
    deadline: {
      required
    }
  },
  computed: {
    categories: state => state.$store.getters.categories,
    mapCategories: state =>
      state.categories.reduce(
        (subAcc, { name, ...others }) => subAcc.set(name, others),
        new Map()
      ),
    projectData: state => ({
      title: state.title,
      category: state.mapCategories.get(state.category).id,
      description: state.description,
      budget: state.budget,
      deadline: state.deadline,
      deadline_type: state.deadlineActive
    }),
    deadlineStatusText: state =>
      state.deadline === "По договорённости"
        ? "Указать срок"
        : "По договорённости",
    deadlineTypes: state => [
      {
        id: 1,
        name: (function() {
          return declinationOfNumber(state.deadline, ["Час", "Часа", "Часов"]);
        })()
      },
      {
        id: 2,
        name: (function() {
          return declinationOfNumber(state.deadline, ["День", "Дня", "Дней"]);
        })()
      },
      {
        id: 3,
        name: (function() {
          return declinationOfNumber(state.deadline, [
            "Неделя",
            "Недели",
            "Недель"
          ]);
        })()
      },
      {
        id: 4,
        name: (function() {
          return declinationOfNumber(state.deadline, [
            "Месяц",
            "Месяца",
            "Месяцев"
          ]);
        })()
      }
    ]
  },
  methods: {
    toggleDeadlineStatus() {
      if (this.deadline === "По договорённости") {
        this.deadline = null;
      } else {
        this.deadline = "По договорённости";
      }
    },
    changeModel(value, model) {
      this[model] = value;
    },
    async createOrder() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      this.loading = true;
      await PROJECTS.create(this.projectData)
        .then(r => {
          const projectId = r.data.projectId;
          this.projectId = projectId;
          this.loading = false;
          this.success = true;
        })
        .catch(e => {
          console.log(e);
        });
    },
    checkNumbers(e) {
      const regExp = /\d/;
      if (!regExp.test(e.key) && e.key !== "Backspace" && e.key !== "Delete")
        return e.preventDefault();
    }
  },
  watch: {
    deadline() {
      if (this.deadlineActive[0] === "Ч") {
        this.deadlineActive = declinationOfNumber(this.deadline, [
          "Час",
          "Часа",
          "Часов"
        ]);
      }
      if (this.deadlineActive[0] === "Д") {
        this.deadlineActive = declinationOfNumber(this.deadline, [
          "День",
          "Дня",
          "Дней"
        ]);
      }
      if (this.deadlineActive[0] === "Н") {
        this.deadlineActive = declinationOfNumber(this.deadline, [
          "Неделя",
          "Недели",
          "Недель"
        ]);
      }
      if (this.deadlineActive[0] === "М") {
        this.deadlineActive = declinationOfNumber(this.deadline, [
          "Месяц",
          "Месяца",
          "Месяцев"
        ]);
      }
    }
  }
};
</script>

<style src="./create-order.scss" lang="scss"></style>
