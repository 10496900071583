import API from "@/API";

export default class ChatsModel extends API {
  getId(projectId) {
    return this._get(
      "/chat/get-id",
      {
        projectId: projectId
      },
      true
    );
  }
  getMy() {
    return this._get("/chat/my-chats", {}, true);
  }

  getOneById(chatId, page) {
    return this._get("/chat/get-messages", { chatId, page }, true);
  }

  sendMessage(text, chatId) {
    return this._post("/chat/send-message", { text, chatId }, true);
  }
}
