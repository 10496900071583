<template lang="pug">
  .page-container
    register-steps(type="freelancer")
    protect(title="Гарантия выплаты средств и защиты исполнителей", body="Мы гарантируем, что ваша работа будет оплачена в полном объёме, если вы соблюдали все договорённости с заказчиком и выполнили его задачу полностью, качественно и в установленные сроки")
    faq(:questions="questions")
</template>

<script>
import RegisterSteps from "../components/app-common/register-steps/register-steps";
import Protect from "../components/app-common/protect/protect";
import Faq from "../components/app-common/faq/faq";
import FaqModel from "@/models/faq.model";

const FAQ = new FaqModel();

export default {
  name: "Customer",
  components: {
    Faq,
    Protect,
    RegisterSteps
  },
  data() {
    return {
      questions: []
    };
  },
  async mounted() {
    await FAQ.getByType("for-freelancer")
      .then(r => {
        this.questions = r.data.map(item => ({
          ...item,
          active: false
        }));
      })
      .catch(e => {
        console.log(e);
      });
  }
};
</script>
