<template lang="pug">
  .tiket.section
    ._wrap.wrap
        navigation(title="Обращения в тех поддержку", :links="links")
        ._container
          appChat(:info="info" :messages="messages")

</template>
<script>
import navigation from "@/components/app-common/app-v-navigation/app-v-navigation";
import appChat from "@/components/app-common/app-chat/app-chat.vue";

export default {
  name: "tiket",
  components: {
    navigation,
    appChat
  },
  data() {
    return {
      info: { number: "Заяка №17612", status: 1 },
      messages: [
        {
          id: 1,
          avatar: "./img/tiket-user.jpg",
          name: "Андрей Колесов",
          date: "10 мая 10:33",
          text:
            '<p class="chat__message-text">При открытии страницы всех заказов и попытке отфильтровать заказы возникает проблема и поэтому я не могу находить нужные мне заказы. Как быть?</p>'
        },
        {
          id: 2,
          avatar: "./img/tiket-agent.jpg",
          name: "Агент Техподдержки",
          date: "10 мая 10:35",
          text:
            '<p class="chat__message-text">Пожалуйста пришлите следующую информацию:</p><p class="chat__message-text">1. Название и версию браузера</p><p class="chat__message-text">2. Операционную систему</p><p class="chat__message-text">3. Разрешение экрана</p>'
        },
        {
          id: 3,
          avatar: "./img/tiket-user.jpg",
          name: "Андрей Колесов",
          date: "10 мая 10:33",
          text:
            '<p class="chat__message-text">Firefox v.67, OC: Windows, 1920x1080</p>'
        },
        {
          id: 4,
          avatar: "./img/tiket-user.jpg",
          name: "Андрей Колесов",
          date: "10 мая 10:33",
          text:
            '<p class="chat__message-text">При открытии страницы всех заказов и попытке отфильтровать заказы возникает проблема и поэтому я не могу находить нужные мне заказы. Как быть?</p>'
        },
        {
          id: 5,
          avatar: "./img/tiket-agent.jpg",
          name: "Агент Техподдержки",
          date: "10 мая 10:35",
          text:
            '<p class="chat__message-text">Пожалуйста пришлите следующую информацию:</p><p class="chat__message-text">1. Название и версию браузера</p><p class="chat__message-text">2. Операционную систему</p><p class="chat__message-text">3. Разрешение экрана</p>'
        },
        {
          id: 6,
          avatar: "./img/tiket-user.jpg",
          name: "Андрей Колесов",
          date: "10 мая 10:33",
          text:
            '<p class="chat__message-text">Firefox v.67, OC: Windows, 1920x1080</p>'
        }
      ],
      links: [
        {
          type: "all",
          name: "Support",
          title: "Все обращения"
        },
        {
          type: "open",
          name: "Support",
          title: "Открытые"
        },
        {
          type: "close",
          name: "Support",
          title: "Закрытые"
        }
      ]
    };
  }
};
</script>
<style lang="scss">
@import "./tiket.scss";
</style>
