import API from "@/API";

export default class ProjectsFeedbackModel extends API {
  create(feedbackData) {
    return this._post("/projects-feedback/", feedbackData, true);
  }
  get(projectId, status) {
    return this._get(
      `/projects-feedback/project/${projectId}`,
      { status },
      true
    );
  }
  getFeedbackByFreelancerId(projectId) {
    return this._get(
      "/projects-feedback/freelancer-to-project",
      { projectId },
      true
    );
  }
  setStatus(feedbackId, status) {
    return this._put(`/projects-feedback/${feedbackId}`, { status }, true);
  }
}
