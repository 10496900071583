<template lang="pug">
.customer-stats
    ._title Статистика заказчика
    ._list
        ._item
            ._item-title Всего заказов
            ._item-value {{ stat.orders }}    
        ._item
            ._item-title Выплачено
            ._item-value {{ stat.summ }}    
        ._item
            ._item-title Отзывов
            ._item-value {{ stat.review }}
</template>

<script>
export default {
  name: "customer-stats",
  props: {
    stat: {
      required: true,
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style src="./customer-stats.scss" lang="scss" scoped></style>
