const projectStatusFilter = value => {
  if (typeof value !== "number") return;

  const statuses = {
    100: "Активный",
    101: "Приостановлен",
    102: "Ожидание решения исполнителя",
    103: "Проект в работе",
    104: "Работа завершена",
    105: "Заказ завершён",
    106: "Исполнитель отказался от заказа",
    107: "Заказчик отказался от заказа",
    108: "Завершён"
  };
  return statuses[value];
};

export default projectStatusFilter;
