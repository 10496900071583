<template lang="pug">
  aside.v-navigation
    ._title {{ title }}
    ._list
      router-link(
        :to="{ name: link.name, params: { type: link.type }}",
        active-class='v-navigation__link_active',
        v-for="(link, index) in links"
        :key="index")._link
        icon(name="arrow-right" component="app-v-navigation")._ico
        |  {{ link.title }}
</template>
<script>
export default {
  name: "v-navigation",
  props: {
    title: {
      type: String,
      required: true
    },
    links: {
      type: Array,
      required: true
    }
  }
};
</script>
<style lang="scss">
@import "./app-v-navigation";
</style>
