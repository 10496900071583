<template lang="pug">
.chat-message
    ._wrap
        ._author-avatar
            app-user-avatar(:avatar="author.avatar" noAvatarBg="blue")
        ._info
            ._info-head
                router-link(:to="{name: 'Profile', params: { id: author.id }}")._author-name {{ author.name }}
                ._date {{ getDate(messageDate, "DD MMMM HH:mm") }}
            ._text {{ message.message }}
</template>

<script>
export default {
  name: "chat-message",
  props: {
    message: {
      default: () => {},
      required: true
    },
    user: {
      default: () => {},
      required: true
    }
  },
  computed: {
    messageDate: state => new Date(state.message.date).getTime(),
    author: state =>
      state.myUser.id === state.message.author_id ? state.myUser : state.user
  }
};
</script>

<style src="./chat-message.scss" lang="scss"></style>
