<template lang="pug">
  section.testimonials.section
    ._wrap.wrap
      h2.title._title Отзывы о работе сервиса
      ._slider.swiper-container(data-slider="testimonials")
        ._arrows
          button._arrow.-prev
            icon(name="arrow-left" component="testimonials")._arrow-ico
          button._arrow.-next
            icon(name="arrow-right" component="testimonials")._arrow-ico
        ._slider-wrapper.swiper-wrapper
          ._slide.swiper-slide(v-for="i in [1, 2, 3, 4, 5]" :key="i")
            ._header
              img(src="./img/testimonial.jpg")._avatar
              ._group
                p._name Андрей Колесов
                span._company CTO Digital-агенства kprod
            ._rating
              icon(name="star" component="common")._star
              icon(name="star" component="common")._star
              icon(name="star" component="common")._star
              icon(name="star" component="common")._star
              icon(name="star" component="common")._star
            ._body Когда наши специалисты заняты, мы постоянно идём на world of job и быстро находим качественных исполнителей, которые делают работу не хуже чем наши ребята
</template>

<script>
import Swiper from "swiper";
import "swiper/swiper-bundle.css";

export default {
  name: "testimonials",
  data() {
    return {
      slider: null
    };
  },
  methods: {
    initSlider() {
      this.slider = new Swiper('[data-slider="testimonials"]', {
        slidesPerView: 2.7,
        spaceBetween: 80,
        centeredSlides: true,
        loop: true,
        slideActiveClass: "testimonials__slide_active",
        navigation: {
          nextEl: ".testimonials__arrow_next",
          prevEl: ".testimonials__arrow_prev"
        },
        breakpoints: {
          320: {
            slidesPerView: 1
          },
          640: {
            slidesPerView: 1.5
          },
          767: {
            slidesPerView: 1.7
          },
          1023: {
            slidesPerView: 2.2
          },
          1399: {
            slidesPerView: 2.7
          }
        }
      });
    }
  },
  mounted() {
    this.initSlider();
  },
  destroyed() {
    this.slider.destroy();
    this.slider = "";
  }
};
</script>

<style lang="scss">
@import "testimonials";
</style>
