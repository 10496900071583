<template lang="pug">
section.task-price.section
  ._bg
  ._wrap.wrap
    h2.title._title Сколько стоит моя задача?
    //p._subtitle Вы сами устанавливаете цену, но можете полагаться на средний цены в каждой категории

    ._container
      ._row
        ._item
          ._group
            .title-sm._title-sm Разработка сайтов
            ._item-row
              ._item-name Сайт «под ключ»
              span._item-price 2000 ₽
            ._item-row
              ._item-name Юзабилити-анализ
              span._item-price 500 ₽
            ._item-row
              ._item-name Проектирование
              span._item-price 500 ₽
            ._item-row
              ._item-name Дизайн сайтов
              span._item-price 500 ₽
            ._item-row
              ._item-name Вёрстка
              span._item-price 3000 ₽
            ._item-row
              ._item-name Веб-программирование
              span._item-price 500 ₽
            ._item-row
              ._item-name Лендинги
              span._item-price 3000 ₽
            ._item-row
              ._item-name Интернет-магазины
              span._item-price 6000 ₽
            ._item-row
              ._item-name Доработка сайтов
              span._item-price 200 ₽

        ._item
          ._group
            .title-sm._title-sm Реклама и маркетинг
            ._item-row
              ._item-name Маркетинг в соц.сетях, SMM
              span._item-price 2000 ₽
            ._item-row
              ._item-name Контекстная реклама
              span._item-price 500 ₽
            ._item-row
              ._item-name Продажи и генерация лидов
              span._item-price 500 ₽
            ._item-row
              ._item-name Сбор и обработка информации
              span._item-price 2000 ₽
            ._item-row
              ._item-name Креатив
              span._item-price 300 ₽
            ._item-row
              ._item-name PR-менеджмент
              span._item-price 500 ₽
            ._item-row
              ._item-name Рекламные концепции
              span._item-price 3000 ₽
            ._item-row
              ._item-name Исследование рынка и опросы
              span._item-price 6000 ₽
            ._item-row
              ._item-name Медиапланирование
              span._item-price 200 ₽

        ._item
          ._group
            .title-sm._title-sm Программирование
            ._item-row
              ._item-name Веб-программирование
              span._item-price 2000 ₽
            ._item-row
              ._item-name Прикладное программирование
              span._item-price 500 ₽
            ._item-row
              ._item-name 1С-программирование
              span._item-price 500 ₽
            ._item-row
              ._item-name Базы данных
              span._item-price 2000 ₽
            ._item-row
              ._item-name Программирование игр
              span._item-price 300 ₽
            ._item-row
              ._item-name Разработка чат-ботов
              span._item-price 500 ₽
            ._item-row
              ._item-name Парсинг данных
              span._item-price 3000 ₽
            ._item-row
              ._item-name Системное программирование
              span._item-price 6000 ₽
            ._item-row
              ._item-name Встраиваемые системы
              span._item-price 200 ₽

    router-link(:to="{name: 'Create'}" tag="button").btn.-blue-darken._btn Создать задачу
</template>

<script>
export default {
  name: "task-price"
};
</script>

<style lang="scss" scoped>
@import "task-price";
</style>
