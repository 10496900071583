<template lang="pug">
    .project-feedback
      app-loading(v-if="loading")
      template(v-else)
        h2._title {{ feedbackTitle }}
        ._list(v-if="feedbacks.length")
          project-feedback-item(v-for="feedback in feedbacks" :key="feedback.id" :feedback="feedback" :projectId="projectId" @setStatus="emit")
        ._no-feedback(v-else) Пока нет предложений от исполнителей
</template>

<script>
import projectFeedbackItem from "@/components/projects/project-feedback-item/project-feedback-item";
import projectsFeedbackModel from "@/models/projectsFeedback.model";
const PROJECTS_FEEDBACK = new projectsFeedbackModel();
export default {
  name: "project-feedback",
  components: { projectFeedbackItem },
  data() {
    return {
      loading: true,
      feedbacks: []
    };
  },
  props: {
    projectId: {
      required: true,
      type: Number
    },
    projectStatus: {
      required: true,
      type: Number
    }
  },
  computed: {
    offerStatus: state => (state.projectStatus === 100 ? 200 : 202),
    feedbackTitle: state =>
      state.projectStatus === 100
        ? "Предложения от исполнителей"
        : "Исполнитель проекта"
  },
  async mounted() {
    await this.getFeedbacks();
  },
  methods: {
    async getFeedbacks() {
      await PROJECTS_FEEDBACK.get(this.projectId, this.offerStatus)
        .then(r => {
          const feedbacks = r.data.feedbacks;
          if (feedbacks.length) {
            this.feedbacks = r.data.feedbacks;
          }
          setTimeout(() => {
            this.loading = false;
          }, 400);
        })
        .catch(() => {
          this.$toasts.success("Не удалось получить список предложений", {
            type: "warning"
          });
        });
    },
    async emit() {
      this.loading = true;
      this.feedbacks = [];
      await this.getFeedbacks();
      this.$emit("setStatus");
    }
  },
  watch: {
    async offerStatus() {
      await this.getFeedbacks();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "project-feedback";
</style>
