<template lang="pug">
  section.about-site.section
    ._wrap.wrap
      h1.title._title О сервисе

      ._container
        ._row
          ._column
            ._body
              p._description World of job — это биржа удаленной работы, которая за несколько минут подберет идеального фрилансера для любого небольшого задания. Являетесь ли вы сотрудником фирмы, студентом или ведете собственный бизнес — так или иначе вы сталкиваетесь с делами, в которых не разбираетесь или на которые у вас не хватает времени. Теперь ваши проблемы будут решать специалисты.
              p._description С сайтом woj.com за несколько кликов мыши вы найдете фрилансеров, которые будут работать для вас быстро, качественно и с удовольствием! Биржа удаленной работы woj.com — ваш личный помощник, который готов выполнить любое задание в удобное для вас время. С помощью нашего сайта вы можете договориться с исполнителем, обменяться файлами и произвести безопасную оплату.
              p._description.-blue Мы полностью гарантируем безопасное осуществление денежных переводов.

          ._column
            img(src="./img/screen.jpg", alt="screenshot")._screen

          ._column
            ._subtitle Для заказчиков
            ._info
              p._description У нас вы можете найти лучших фриланс специалистов среди 1 млн исполнителей, зарегистрированных на сайте woj.ru. Программисты, дизайнеры, художники, копирайтеры, юристы, бухгалтеры, инженеры, фотографы - тысячи удаленных сотрудников по любым freelance специализациям.
              p._description Вам достаточно опубликовать проект, конкурс или вакансию - и заинтересованные фрилансеры сами предложат свои услуги, помогут вам сформировать задание, определить бюджет и сроки выполнения работы. Останется только выбрать лучшего исполнителя из числа откликнувшихся фриланс специалистов и начать с ним сотрудничество.
              p._description Если же совсем нет времени на проекты, вы можете быстро найти удаленных специалистов в каталоге, оценив их портфолио с представленными работами - и в 2 клика предложить заказ.
              p._description woj.ru за безопасность freelance сотрудничества! Используйте наш сервис "Безопасная сделка" с резервированием суммы на сайте для сотрудничества с фрилансерами - и мы гарантируем вам возврат средств, если работа будет выполнена некачественно и/или не в срок.
              p._description.-blue Мы полностью гарантируем безопасное осуществление денежных переводов.

            register-btn(type="customer")

          ._column
            ._subtitle Для исполнителей
            ._info
              p._description Для вас ежедневно свыше 1500 фриланс проектов, конкурсов и вакансий с поиском исполнителей. Если вы ищете удаленную работу фрилансером и умеете создавать сайты, писать тексты, администрировать, консультировать, оказывать freelance услуги на дому - добро пожаловать на сайт woj.ru.
              p._description Начиная работу с нашим сайтом, вам прежде всего необходимо заполнить портфолио фрилансера, добавив примеры успешно выполненных вами проектов и заказов. Указать в профиле всю необходимую информацию о своих навыках и опыте, добавить контактные данные.
              p._description Фрилансерам для получения работы рекомендуем регулярно просматривать ленту проектов, отвечая на подходящие вам предложения - в случае заинтересованности заказчики обязательно предложат вам сотрудничество по freelance проектам с дальнейшим выполнением работы.
              p._description Чтобы повысить шансы получить работу, также рекомендуем фрилансеру приобрести аккаунт PRO - сервис, который значительно расширит возможности вашего фриланс профиля и поможет привлечь еще больше выгодных заказов.
              p._description.-blue Успешного поиска работы!

            register-btn(type="freelancer")
  
</template>
<script>
import RegisterBtn from "../../app-common/register/register-btn/register-btn";
export default {
  name: "about-site",
  components: { RegisterBtn }
};
</script>

<style lang="scss">
@import "about-site";
</style>
