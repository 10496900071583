import API from "@/API";

export default class ProjectsModel extends API {
  getOneById(id) {
    return this._get(`/projects/${id}`);
  }

  my(filterFields) {
    return this._get("/projects/my/", filterFields, true);
  }

  getFiltered(filterFields) {
    return this._get("/projects/", filterFields);
  }

  countFiltered(filterFields) {
    return this._get("/projects/count/", filterFields);
  }

  create(data) {
    return this._post("/projects/", data, true);
  }

  getCountByUserId(userId) {
    return this._get(`/projects/count/${userId}`);
  }

  setStatus(projectId, status, freelancerId) {
    return this._put(
      `/projects/${projectId}/set-status`,
      { status, freelancer: freelancerId },
      true
    );
  }
}
