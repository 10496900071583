import API from "@/API";

export default class TransactionsModel extends API {
  get() {
    return this._get("/transactions/", {}, true);
  }
  create(summ) {
    return this._post("/transactions/create", { summ }, true);
  }
}
