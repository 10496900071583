import axios from "axios";

let API_INSTANCE = null;

class API {
  constructor() {
    this.token = localStorage.token;
    this.refreshToken = localStorage.refreshToken;
    this.userId = localStorage.userId;

    this.axios = axios.create({
      baseURL: process.env.VUE_APP_API_URL
    });

    this.axios.interceptors.response.use(
      r => r,
      error => {
        console.log(error);
        const { status } = error.response;
        if (status === 401) {
          const refreshToken = this.refreshToken;
          if (refreshToken) {
            this._get(`/auth/refresh`, {
              refreshToken: refreshToken,
              userId: this.userId
            })
              .then(async r => {
                this.setTokens = await r.data;
                location.reload();
              })
              .catch(async () => {
                await this.logout();
              });
          }
        }
        throw error;
      }
    );
  }

  static get instance() {
    if (API_INSTANCE === null) {
      API_INSTANCE = new API();
    }
    return API_INSTANCE;
  }

  set setTokens(data) {
    this.token = data.token;
    this.refreshToken = data.refreshToken;
    this.userId = data.userId;
    localStorage.token = data.token;
    localStorage.refreshToken = data.refreshToken;
    localStorage.userId = data.userId;
  }

  logout() {
    this.token = null;
    this.refreshToken = null;
    this.userId = null;
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userId");
  }

  getMyUser() {
    return this._get("/users/my/", {}, true);
  }

  _api(method, apiMethod, data, onlyAuthorized) {
    let _headers = {
      "Content-Type": "application/json"
    };

    if (onlyAuthorized) {
      const token = this.token || localStorage.token || null;
      if (!token) throw new Error("No token");
      else _headers["Authorization"] = "Bearer " + token;
    }

    return this.axios({
      method,
      url: apiMethod,
      headers: _headers,
      data: data && method !== "get" ? data : {},
      params: data && method == "get" ? data : {}
    });
  }

  _get(endpoint, data, noauth) {
    return this._api("get", endpoint, data, noauth);
  }
  _post(endpoint, data, noauth) {
    return this._api("post", endpoint, data, noauth);
  }
  _put(endpoint, data, noauth) {
    return this._api("put", endpoint, data, noauth);
  }
  _delete(endpoint, data, noauth) {
    return this._api("delete", endpoint, data, noauth);
  }
}

export default API;
