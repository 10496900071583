<template lang="pug">
app-modal(:visible="visible" @close="closeChat()")
    template(v-slot:content)
        .chat
            ._wrap
                ._list
                    ._list-header Список чатов
                      ._list-header-count {{ chats.length }}
                    ._list-wrap
                      chat-preview(v-for="chat in chats" @click.native="setActiveChat(chat)" :userId="isFreelancer ? chat.customer_id : chat.freelancer_id" :projectId="chat.project_id" :class="{active: chat.id === activeChatId}" :key="chat.id")
                ._content
                  ._content-info(v-if="!messages.length && !activeChatId") Выберите чат, чтобы начать общение
                  ._content-info(v-if="!messages.length && activeChatId") В этом чате пока нет сообщений
                  vue-custom-scrollbar._message-list(v-if="messages.length && activeChatId" ref="scrollWrapper" settings="scrollSettings")
                    ._message-list-wrap
                      chat-message(v-for="message in messages" :key="message.id" :message="message" :user="activeChatUser")
                  ._form(v-if="activeChatId") 
                    textarea(v-model="text" placeholder="Введите сообщение...")._form-textarea
                    button(@click="sendMessage")._send-message
                      icon(name="send" component="chat")._send-message-icon
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import chatPreview from "../chat-preview/chat-preview";
import chatMessage from "../chat-message/chat-message";
import ChatsModel from "@/models/chats.model";
import UsersModel from "@/models/users.model";
import inTheWorksVue from "../../app-common/in-the-works/in-the-works.vue";
import { io } from "socket.io-client";

const USERS = new UsersModel();
const CHATS = new ChatsModel();

export default {
  name: "chat",
  components: { chatPreview, chatMessage, vueCustomScrollbar },
  props: {
    visible: {
      type: Boolean,
      default: inTheWorksVue
    },
    activeChat: {
      required: false,
      default: 133
    }
  },
  data() {
    return {
      socket: io(process.env.VUE_APP_CHAT_URL),
      chats: [],
      activeChatUser: null,
      activeChatId: null,
      messages: [],
      text: null,
      scrollSettings: {
        wheelPropagation: false,
        scrollYMarginOffset: 200
      }
    };
  },
  async created() {
    await this.getChats();

    this.socket.on("new-message", message => {
      console.log("new message", message);
      if (message.chatId === this.activeChatId) {
        this.messages.push({
          date: new Date().getTime(),
          message: message.text,
          author_id: this.activeChatUser.id
        });
        this.scrollToBottom();
      }
    });
  },
  methods: {
    async getChats() {
      await CHATS.getMy().then(r => {
        this.chats = r.data.chats;
        if (this.activeChat) {
          this.setActiveChat(
            this.chats.find(chat => chat.id === this.activeChat)
          );
        }
      });
    },
    async setActiveChat(chat) {
      this.activeChatId = chat.id;
      this.$store.dispatch("SET_ACTIVE_CHAT_ID", chat.id);
      await CHATS.getOneById(chat.id, 1).then(r => {
        if (r.data.count) {
          this.messages = r.data.messages;
        } else {
          this.messages = [];
        }
        this.socket.emit("connect-to-room", { id: this.activeChatId });
      });

      setTimeout(() => {
        this.scrollToBottom();
      }, 100);

      let user = this.isFreelancer ? chat.customer_id : chat.freelancer_id;
      await USERS.getOneById(user).then(r => {
        this.activeChatUser = r.data.user;
      });
    },
    async sendMessage() {
      if (this.text.length === 0) return;
      await CHATS.sendMessage(this.text, this.activeChatId).then(r => {
        if (r.data.success) {
          this.socket.emit("send-message", {
            chatId: this.activeChatId,
            text: this.text
          });
          this.messages.push({
            date: new Date().getTime(),
            message: this.text,
            author_id: this.myUser.id
          });
          this.text = null;
          this.scrollToBottom();
        }
      });
    },
    scrollToBottom() {
      this.$nextTick(() => {
        this.$refs.scrollWrapper.$el.scrollTop =
          this.$refs.scrollWrapper.$el.scrollHeight -
          this.$refs.scrollWrapper.$el.clientHeight;
      });
    },
    closeChat() {
      this.$store.dispatch("SET_CHAT_STATUS", false);
      this.$store.dispatch("SET_ACTIVE_CHAT_ID", null);
    }
  },
  watch: {}
};
</script>

<style src="./chat.scss" lang="scss"></style>
