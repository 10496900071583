<template lang="pug">
  .page-container.section.static
    .wrap
      component(:is="page")
</template>

<script>
import confidential from "@/components/static-pages/confidential";
import agreement from "@/components/static-pages/agreement";
import requisites from "@/components/static-pages/requisites";

export default {
  name: "Profile",
  components: {
    confidential,
    agreement,
    requisites
  },
  computed: {
    page: state => state.$route.params.page
  }
};
</script>

<style lang="scss">
.static {
  padding: 80px 0;

  p {
    font-size: 16px;
    line-height: 1.5;
    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  h4 {
    font-size: 20px;
    margin-top: 40px;
  }
}
</style>
