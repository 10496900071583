<template lang="pug">
.success
    ._title {{ title }}
    router-link(type="button" tag="button" :to="to").btn.-blue._btn {{ btnText }}    
</template>

<script>
export default {
  name: "app-success",
  props: {
    title: {
      type: String
    },
    to: {
      type: Object
    },
    btnText: {
      type: String
    }
  }
};
</script>

<style lang="scss" scoped>
.success {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 90px - 49px - 406px);
  min-height: 300px;

  &__title {
    font-size: 18px;
  }

  &__btn {
    margin-top: 40px;
  }
}
</style>
