<template lang="pug">
.project-full.section
  ._wrap.wrap
    app-loading(v-if="loading")
    project(:key="project.id" :project="project" type="full")
    project-feedback(v-if="isMyProject" :projectId="project.id" :projectStatus="project.status" @setStatus="getProject()")
    template(v-if="user.group === 'freelancer' && user.group !== 'guest' && !project.freelancer")
      add-feedback(v-if="myFeedback == null" :project-id="project.id" @newFeedback="getMyFeedback")
      project-feedback-item(v-else-if="myFeedback" :feedback="myFeedback" :projectId="projectId" :showMyFeedback="true")
    app-info(v-if="project.freelancer === user.id" type="success") Поздравляем! Заказчик выбрал вас исполнителем проекта!
</template>

<script>
import project from "@/components/projects/project/project";
import addFeedback from "@/components/projects/add-feedback/add-feedback";

import ProjectsModel from "@/models/projects.model";
import projectsFeedbackModel from "@/models/projectsFeedback.model";
import projectFeedback from "@/components/projects/project-feedback/project-feedback";
import projectFeedbackItem from "@/components/projects/project-feedback-item/project-feedback-item";

const PROJECTS = new ProjectsModel();
const PROJECTS_FEEDBACK = new projectsFeedbackModel();

export default {
  name: "project-full",
  components: {
    project,
    projectFeedback,
    addFeedback,
    projectFeedbackItem
  },
  data() {
    return {
      loading: true,
      project: {},
      myFeedback: null
    };
  },
  computed: {
    isMyProject: state =>
      state.user.group === "customer" &&
      state.project.customer === state.user.id,
    projectId: state => Number(state.$route.params.id),
    user: state => state.$store.getters["user/info"],
    offerStatus: state => (state.project.status === 100 ? 200 : 202)
  },
  async created() {
    if (this.projectId) {
      await this.getProject();
      if (this.user.group === "freelancer") await this.getMyFeedback();
      setTimeout(() => {
        this.loading = false;
      }, 300);
    }
  },
  methods: {
    async getProject() {
      await PROJECTS.getOneById(this.projectId)
        .then(r => {
          this.project = r.data.project;
        })
        .catch(() => {
          this.$router.replace({ name: "404" });
        });
    },
    async getMyFeedback() {
      await PROJECTS_FEEDBACK.getFeedbackByFreelancerId(this.project.id).then(
        r => {
          if (r.data.error === undefined) {
            this.myFeedback = r.data.feedbacks;
          }
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import "project-full";
</style>
