<template lang="pug">
section.services.section
  ._wrap.wrap
    ._header
      h2.title._title Какие услуги вы можете заказать?
      a(href="#!")._show-more
        | Смотреть все
        icon(name="arrow-right" component="services")._arrow
    ._container
      ._row
        ._item(v-for="item in categories" :key="item.id" v-if="!item.parentId && item.id < 4")
          icon(:name="item.icon" component="services")._item-ico
          ._group
            .title-sm._title-sm {{ item.name }}
            ._body
              p._info(v-for="category in categories" :key="category.id" v-if="category.parentId === item.id") {{ category.name }} 

</template>

<script>
export default {
  name: "services",
  computed: {
    categories: state => state.$store.getters.categories
  }
};
</script>

<style src="./services.scss" lang="scss" scoped></style>
