<template lang="pug">
.page-container
  banner
  services
  howWork
  testimonials
  best-freelancers
  register-menu(v-if="isGuest")
</template>

<script>
import banner from "../components/home-page/banner/banner";
import services from "../components/home-page/services/services";
import howWork from "../components/home-page/how-work/how-work";
import Testimonials from "../components/home-page/testimonials/testimonials";
import BestFreelancers from "../components/home-page/best/best";
import RegisterMenu from "../components/app-common/register/register";

export default {
  name: "Home",
  components: {
    RegisterMenu,
    BestFreelancers,
    Testimonials,
    banner,
    services,
    howWork
  },
  computed: {
    userGroup: state => state.$store.getters["user/group"]
  },
  async created() {
    const redirect = await localStorage.redirect;
    if (redirect) {
      localStorage.redirect = "";
      if (this.userGroup === "freelancer" && redirect === "Create") return;
      this.$router.push({ name: redirect });
    }
  }
};
</script>
