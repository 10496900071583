<template lang="pug">
.page-container
  auth
</template>

<script>
import auth from "../components/auth/auth";

export default {
  name: "Auth",
  components: { auth }
};
</script>
