import Vue from "vue";

import AppInfo from "@/components/app-common/app-info/app-info";
import AppInput from "@/components/app-common/app-input/app-input";
import Icon from "@/components/app-common/icon/icon";
import AppLoading from "@/components/app-common/app-loading/app-loading";
import UserAvatar from "@/components/app-common/user-avatar/user-avatar";
import AppButton from "@/components/app-common/app-button/app-button";
import AppModal from "@/components/app-common/app-modal/app-modal";

Vue.component("icon", Icon);
Vue.component("app-loading", AppLoading);
Vue.component("app-input", AppInput);
Vue.component("app-info", AppInfo);
Vue.component("app-user-avatar", UserAvatar);
Vue.component("app-button", AppButton);
Vue.component("app-modal", AppModal);
