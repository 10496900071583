<template lang="pug">
section.how-work.section
  ._bg
  ._wrap.wrap
    h2.title._title Как работает наш сервис?
    ._container
      ._row
        ._item
          img(src="./img/form.svg", alt="Создание задачи")._ico
          .title-sm._title-sm Создание задачи
          ._description Вы сами устанавливаете цену и срок, за который ваша задача должна быть выполнена

        ._item
          img(src="./img/selection.svg", alt="Выбор исполнителя")._ico
          .title-sm._title-sm Выбор исполнителя
          ._description Вы получаете предложения от исполнителей и сами решаете кто будет выполнять вашу задачу.

        ._item
          img(src="./img/payment.svg", alt="Оплачиваете работу")._ico
          .title-sm._title-sm Оплачиваете работу
          ._description После того, как исполнитель закончил задачу, вы её проверяете и принимаете решение об оплате.

    router-link(:to="{name: 'Create'}" tag="button" type="button" v-if="!isFreelancer").btn.-blue-darken._btn Создать заказ
</template>

<script>
export default {};
</script>

<style src="./how-work.scss" lang="scss" scoped></style>
