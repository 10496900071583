<template lang="pug">
.page-container
  create-order
</template>

<script>
import CreateOrder from "../components/create-order/create-order";
export default {
  name: "Create-order",
  components: { CreateOrder },
  computed: {
    userType: state => state.$store.getters["user/type"]
  },
  mounted() {}
};
</script>
