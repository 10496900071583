<template lang="pug">
  .project-feedback-item
    ._header
      router-link(:to="{name: 'Profile', params: { id: freelancer.id }}")._user
        ._user-avatar
          user-avatar(:avatar="freelancer.avatar")
        ._user-info
          ._user-name {{ freelancer.name }}
          ._user-projects Выполненных проектов: {{ projectsCount }}
    ._content
      ._text {{ feedback.description }}
      ._offer
        ._offer-group
          ._offer-title Срок:
          ._offer-value {{ feedback.deadline }}
        ._offer-group
          ._offer-title Цена:
          ._offer-value {{ feedback.price }} ₽
      button(@click="openChat()" v-if="chatId").btn.-blue.-small._chat-btn Открыть чат
    ._footer(v-if="feedback.status === 200 && !showMyFeedback")
      button(@click="setStatus(102, 202)").btn.-blue.-small._btn Выбрать исполнителем
      button(@click="setStatus(false, 201)").btn.-red.-small._btn Отказать
      button(@click="openChat()" v-if="chatId").btn.-blue.-small._btn Открыть чат
</template>

<script>
import UsersModel from "@/models/users.model";
import ProjectsModel from "@/models/projects.model";
import ProjectsFeedbackModel from "@/models/projectsFeedback.model";
import userAvatar from "@/components/app-common/user-avatar/user-avatar";
import ChatsModel from "@/models/chats.model";

const USERS = new UsersModel();
const PROJECTS = new ProjectsModel();
const PROJECTS_FEEDBACK = new ProjectsFeedbackModel();
const CHATS = new ChatsModel();

export default {
  name: "feedback-feedback-item",
  components: { userAvatar },
  props: {
    feedback: {
      required: true,
      default: () => {}
    },
    projectId: {
      required: true,
      type: Number,
      default: 0
    },
    showMyFeedback: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      freelancer: {},
      projectsCount: 0,
      chatId: 0
    };
  },
  methods: {
    async getFreelancerInfo() {
      await USERS.getOneById(this.feedback.freelancer_id).then(r => {
        this.freelancer = r.data.user;
      });
    },
    async getFreelancerProjectsCount() {
      await PROJECTS.getCountByUserId(this.freelancer.id).then(r => {
        this.projectsCount = r.data.count;
      });
    },
    async getChatId() {
      await CHATS.getId(this.projectId).then(r => {
        this.chatId = r.data.id;
      });
    },
    async setStatus(projectStatus, offerStatus) {
      await PROJECTS_FEEDBACK.setStatus(this.feedback.id, offerStatus);
      if (projectStatus) {
        await PROJECTS.setStatus(
          this.projectId,
          projectStatus,
          this.freelancer.id
        );
      }
      this.$emit("setStatus");
    },
    openChat() {
      this.$store.dispatch("SET_CHAT_STATUS", true);
      this.$store.dispatch("SET_ACTIVE_CHAT_ID", this.chatId);
    }
  },
  async mounted() {
    await this.getFreelancerInfo();
    await this.getFreelancerProjectsCount();
    await this.getChatId();
  }
};
</script>

<style lang="scss" scoped>
@import "project-feedback-item";
</style>
