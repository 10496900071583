<template lang="pug">
    .profile-projects
        ._tabs
          ._tab(v-for="t in tabs" :key="t.name" @click="tab = t.name" :class="{active: tab === t.name}") {{ t.title }}
        ._list(:class="{loading: loading}")
          app-loading(v-if="loading")
          project(v-for="project in projects" :key="project.id" :project="project")
          app-info(v-if="!projects.length") Не найдено ни одного проекта  
</template>

<script>
import projectsModel from "@/models/projects.model";
const PROJECTS = new projectsModel();
import project from "@/components/projects/project/project";
export default {
  name: "profile-projects",
  components: {
    project
  },
  data() {
    return {
      loading: true,
      page: 1,
      tab: "all",
      tabs: [
        { name: "all", title: "Все" },
        { name: "in-work", title: "Активные" },
        { name: "completed", title: "Завершённые" }
      ],
      projects: []
    };
  },
  computed: {
    status: state =>
      state.tab === "all"
        ? ""
        : state.tab === "in-work"
        ? "100,102,103,104,105"
        : "101,106,107,109",
    filter: state => ({
      status: state.status,
      page: state.page
    })
  },
  async mounted() {
    await this.getProjects();
  },
  methods: {
    async getProjects() {
      this.loading = true;
      PROJECTS.my(this.filter).then(r => {
        const projects = r.data.list;
        if (projects.length) {
          this.page++;
          projects.map(project => this.projects.push(project));
        }
        setTimeout(() => {
          this.loading = false;
        }, 300);
      });
    }
  },
  watch: {
    async tab() {
      this.projects = [];
      this.page = 1;
      await this.getProjects();
    },
    async page() {
      if (this.page <= 1) return;
      await this.getProjects();
    }
  }
};
</script>

<style src="./profile-projects.scss" lang="scss"></style>
