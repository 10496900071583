<template lang="pug">
  section.in-the-works.section
    ._wrap.wrap
      ._title Данный раздел находится в разработке
      router-link(:to="{name: 'Home'}").btn.-blue._btn Вернуться на главную
</template>

<script>
export default {
  name: "in-the-works"
};
</script>

<style lang="scss">
.in-the-works {
  height: calc(100vh - 90px - 49px - 406px);
  min-height: 300px;
  @media (max-width: 767px) {
    padding: 60px 0;
  }

  &__wrap {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__title {
    font-size: 24px;
    text-align: center;
  }

  &__btn {
    margin-top: 40px;
  }
}
</style>
