<template lang="pug">
    .app-input
        label._label(v-if="label") {{ label }}
        ._group
          input(autocomplete="off" :type="type" :value="value" :placeholder="placeholder" v-mask="mask" @input="$emit('update:model', $event.target.value)" :class="{error: validator}" @keydown="checkNumbers")._field
          ._unit(v-if="unit") {{ unit }}
        ._error(v-if="validator") {{ error }}
</template>

<script>
export default {
  name: "app-input",
  data() {
    return {
      model: "",
      phone: "null"
    };
  },
  props: {
    type: {
      required: true,
      type: String,
      default: "text"
    },
    value: {
      default: ""
    },
    validator: {
      type: Boolean,
      required: false,
      default: false
    },
    error: {
      type: String,
      required: false,
      default: ""
    },
    placeholder: {
      type: String,
      required: false,
      default: ""
    },
    label: {
      type: String,
      required: false,
      default: ""
    },
    unit: {
      type: String,
      required: false,
      default: ""
    },
    mask: {
      type: String,
      required: false,
      default: ""
    },
    numbersOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    checkNumbers(e) {
      if (this.numbersOnly) {
        const regExp = /\d/;
        if (!regExp.test(e.key) && e.key !== "Backspace" && e.key !== "Delete")
          return e.preventDefault();
      }
    }
  }
};
</script>

<style src="./app-input.scss" lang="scss" scoped></style>
