<template lang="pug">
    nav.profile-nav
        router-link(:to="{ name: 'Profile', params: { id: userId, type: 'main' } }" active-class="active")._link Мой профиль
        router-link(:to="{ name: 'Profile', params: { id: userId, type: 'projects' } }" active-class="active")._link Мои заказы
        router-link(:to="{ name: 'Profile', params: { id: userId, type: 'vacancy' } }" active-class="active")._link Вакансии
        router-link(:to="{ name: 'Profile', params: { id: userId, type: 'referral-program' } }" active-class="active")._link Реферальная программа
        router-link(:to="{ name: 'Profile', params: { id: userId, type: 'balance' } }" active-class="active")._link Баланс
</template>

<script>
export default {
  name: "profile-nav",
  computed: {
    userId: state => state.$route.params.id
  }
};
</script>

<style src="./profile-nav.scss" lang="scss"></style>
