<template lang="pug">
    form(@submit.prevent="saveProfile").profile-settings
        template(v-if="!info")
          ._title Редактирование профиля  
          ._group
              app-input(type="text" v-bind:model.sync="name" :value="name" placeholder="Введите имя" label="Имя" :validator="$v.name.$error" error="Имя не может быть короче 3 символов")._input
              app-input(type="text" :model.sync="lastName" :value="lastName" placeholder="Введите фамилию" label="Фамилия" :validator="$v.lastName.$error" error="Фамилия не может быть короче 3 символов")._input
          ._group
              app-input(type="text" :model.sync="city" :value="city" placeholder="Введите город" label="Город" :validator="$v.city.$error" error="Название города не может быть короче 3 символов")._input
              app-input(type="date" :model.sync="birthday" placeholder="Дата рождения" label="Дата рождения")._input
          ._group
              app-input(type="text" :model.sync="email" :value="email" placeholder="Введите email" label="Email" :validator="$v.email.$error" error="Введите корректный email-адрес")._input
              app-input(type="text" :model.sync="phone" :value="phone" placeholder="Номер телефона" label="Номер телефона" mask="+# (###) ### ##-##" :validator="$v.phone.$error" error="Введите корректный номер телефона")._input
          ._group
              app-button(@click.native="saveProfile" :disabled="haveNoChanges") Сохранить
        ._group(v-else)
          app-info(type="success") {{ info }}
</template>

<script>
import {
  email,
  required,
  minLength,
  maxLength
} from "vuelidate/lib/validators";

import usersModel from "@/models/users.model";
const USERS = new usersModel();

export default {
  name: "profile-settings",
  data() {
    return {
      name: "",
      lastName: "",
      city: "",
      birthday: "",
      email: "",
      phone: "",
      info: "",
      user: {}
    };
  },
  computed: {
    userData: state => ({
      name: state.name,
      last_name: state.lastName,
      city: state.city,
      birthday: state.birthday,
      email: state.email,
      phone: state.phone
    }),
    haveNoChanges: state =>
      state.name === state.user.name &&
      state.lastName === state.user.last_name &&
      state.city === state.user.city &&
      state.birthday === state.user.birthday &&
      state.phone === state.user.phone &&
      state.email === state.user.email
  },
  validations: {
    name: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(15)
    },
    lastName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(15)
    },
    city: {
      required,
      minLength: minLength(3)
    },
    email: {
      required,
      email
    },
    phone: {
      minLength: minLength(18),
      maxLength: maxLength(18)
    }
  },
  async created() {
    await this.getMyData();
  },
  methods: {
    async saveProfile() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      if (this.haveNoChanges) {
        return;
      }
      USERS.saveMyData(this.userData).then(r => {
        if (r.data.message == "success") {
          this.$emit("saved");
        }
      });
    },
    async getMyData() {
      USERS.getMyData().then(r => {
        const userData = r.data;
        this.user = userData;
        this.name = userData.name;
        this.lastName = userData.last_name;
        this.city = userData.city;
        this.email = userData.email;
        this.phone = userData.phone;
        this.birthday = userData.birthday;
      });
    }
  }
};
</script>

<style src="./profile-settings.scss" lang="scss" scoped></style>
