<template lang="pug">
    .app-user-menu
        ._header(@click="dropdownStatus = !dropdownStatus" v-click-outside="closeDropdown")
            ._header-group
              ._name {{ user.name }}
              ._group {{ user.group | userTypes }}
            ._avatar
              app-user-avatar
        transition(name="fadeInDown")
            ._dropdown(v-if="dropdownStatus")
                ._dropdown-group
                  router-link(:to="{name: 'Profile', params: { id: myUserId }}" tag="a")._dropdown-item
                    span._dropdown-item-title Мой профиль
                  router-link(:to="{name: 'Profile', params: { id: myUserId, type: 'projects' }}" tag="a")._dropdown-item
                    span._dropdown-item-title Мои заказы
                  router-link(:to="{name: 'Profile', params: { id: myUserId, type: 'vacancy' }}" tag="a")._dropdown-item
                    span._dropdown-item-title Мои вакансии
                ._dropdown-group
                  ._dropdown-item(@click="logout")
                    span._dropdown-item-title Выйти
              
</template>

<script>
import vClickOutside from "v-click-outside/dist/v-click-outside.umd";
import AuthModel from "@/models/auth.model";
import API from "@/API";
const AUTH = new AuthModel();

export default {
  name: "app-user-menu",
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      dropdownStatus: false
    };
  },
  computed: {
    myUserId: state => state.$store.getters["user/id"],
    user: state => state.$store.getters["user/info"]
  },
  methods: {
    async logout() {
      await AUTH.logout().then(() => {
        console.log(API.instance);
        API.instance.setTokens = {};
        localStorage.token = "";
        localStorage.refreshToken = "";
        localStorage.userId = "";
        this.$store.dispatch("user/RESET_DATA");
      });
    },
    closeDropdown() {
      setTimeout(() => {
        this.dropdownStatus = false;
      }, 50);
    }
  }
};
</script>

<style src="./app-user-menu.scss" lang="scss" scoped></style>
