<template lang="pug">
footer.footer.section
  ._wrap.wrap
    ._left
      img(src="./img/footer-logo.svg", alt="")._logo
      p._description Помогаем заказчикам и исполнителям находить друг друга для совершения безопасных сделок
      router-link(:to="{name: 'Static', params: { page: 'confidential' }}" tag="a")._link Политика конфиденциальности
      router-link(:to="{name: 'Static', params: { page: 'agreement' }}" tag="a")._link Пользовательское соглашение
      router-link(:to="{name: 'Static', params: { page: 'requisites' }}" tag="a")._link Реквизиты

    nav._nav.-order-first
      router-link(:to="{name: 'About'}" tag="a" active-class="active")._nav-link О сервисе
      router-link(:to="{name: 'Freelancer'}" tag="a" active-class="active")._nav-link Исполнителям
      router-link(:to="{name: 'Customer'}" tag="a" active-class="active")._nav-link Заказчикам

    nav._nav.-order-lastS
      router-link(:to="{name: 'News'}" tag="a")._nav-link Новости
      router-link(:to="{name: 'Freelancers'}" tag="a")._nav-link Все исполнители
      router-link(:to="{name: 'Projects'}" tag="a")._nav-link Найти работу
      router-link(:to="{name: 'Support', params: { type: 'all' }}" tag="a")._nav-link Поддержка

    ._right
      ._subtitle Мы в социальных сетях:
      ._social
        a(href="#!", rel="noopener noreferrer")._social-link.-vk VK

        a(href="#!", rel="noopener noreferrer")._social-link.-fb FB

        a(href="#!", rel="noopener noreferrer")._social-link.-twitter TW

      ._subtitle Остались вопросы?
      button.btn.-blue-darken._btn Задать вопрос
</template>

<script>
export default {};
</script>

<style src="./footer.scss" lang="scss" scoped></style>
