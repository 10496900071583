<template lang="pug">
    .app-layout
      app-header 
      | {{ activeChatId }}
      app-nav
      router-view
      app-footer
      chat(v-if="isChatOpen" :visible="isChatOpen" :activeChat="activeChatId")
</template>

<script>
import appHeader from "../components/app-common/app-header/app-header";
import appNav from "../components/app-common/app-nav/app-nav";
import appFooter from "../components/app-common/app-footer/app-footer";
import chat from "@/components/chats/chat/chat";
import CategoriesModel from "@/models/categories.model";

const CATEGORIES = new CategoriesModel();

export default {
  name: "app-layout",
  components: { appHeader, appNav, appFooter, chat },
  computed: {
    isChatOpen: state => state.$store.getters["isChatOpen"],
    activeChatId: state => state.$store.getters["activeChatId"]
  },
  async mounted() {
    await CATEGORIES.get()
      .then(r => {
        this.$store.dispatch("SAVE_CATEGORIES", r.data);
      })
      .catch(() => {
        this.$toasts.warning("Не удалось получить список категорий");
      });
  }
};
</script>
