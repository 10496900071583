<template lang="pug">
  section.post.section
    ._wrap.wrap
      navigation(title="Категории новостей", :links="links")
      ._container
        h1._title Верификация не обязательна, но повышает шансы получить задание.

        ._tags
          .post__tag(v-for="(tag, index) in tags" :key="index")
            .post__tag-label {{ tag.cat }}
            span.post__tag-btn {{ tag.info }}

        ._body
          p._text Как вы знаете, на WOJ вы можете пройти процедуру верификации паспорта, и в вашем профиле появится специальный значок. Процедура обязательна для тех исполнителей, которые выводят деньги на кошельки QIWI, всем остальным — опционально. Но, проанализировав некоторые инсайдерские цифры, мы решили поделиться этой информацией с вами.

          p._text В целом верифицированные паспорта имеют примерно 18% исполнителей сервиса. Среди активных фрилансеров, которые имеют оплаченную подписку и откликаются на задания, этот процент выше — 30,6%.

          p._text За проанализированный период в 42% случаев исполнителем был назначен пользователь с верифицированным паспортом. Похоже заказчики доверяют им больше, чем пользователям-анонимам. В целом, логично, конечно.

          strong._text.-strong Это безопасно?

          p._text Очень важная информация: WOJ не хранит у себя данные пользователей. Сотрудники сервиса не верифицируют ваши паспорта и вообще не видят ваши личные данные. Этими операции мы делегировали надёжному специальному сертифицированному сервису Sum&Substance.

          p._text Sum&Substance зарегистрирован Роскомнадзоров как оператор по обработке персональных данных. Свидетельство о регистрации: № 78-17-003488

          p._text Все данные передаются по защищённому каналу на основе TLS 1.2 и хранятся в зашифрованном виде.

          p._text Дата-центры Sum&Substance по уровню надежности и отказоустойчивости соответствует требованиям стандарта Tier III по классификации Uptime Institute и стандарта TIA-942 и имеет сертификат PCI DSS.

          strong._text.-strong Как пройти верификацию?

          p._text Процедура платная. Стоимость: 50₽. Для её успешного прохождения, на вашей аватарке должно быть реальное фото, а имя и фамилия в профиле написаны так же, как в паспорте.

          p._text Через специальную форму вы загружаете две фотографии: 1). фото (или скан) паспорта. Снимок должен быть чётким, без срезанных углов, без пальцев в кадре и т.д. 2). Ваше селфи с раскрытым паспортом.

          p._text Sum&Substance с помощью автоматизированной технологии проверки изображения на цифровую подлинность сравнит изображения, и, если всё прошло успешно, через пару минут в профиле появится галочка и надпись «Паспорт проверен».

          p._text Важно! После того, как вы прошли этот квест, пожалуйста, не редактируйте аватарку, имя и фамилию. Иначе верификация слетит...

          strong._text.-strong Summary (если нет времени читать целиком)

          p._text Верификация паспорта не обязательна на сервисе. Однако, статистика показывает, что заказчики воспринимают верифицированных кандидатов как более благонадёжных и добросовестных.

          p._text Процедура безопасна. Верификацией занимается специальный сертифицированный сервис, имеющий регистрацию Роскомнадзора как оператор персональных данных. У них ого-го-какая система шифрования и протоколы безопасности соответствуют всем стандартам.


        ._share
          ._share-title Поделиться в социальных сетях:
          ._share-list
            a(href="#!" v-for="(item, index) in share" :key="index" :class="item.classname")._share-link
              icon(:name="item.ico" component="post")._share-ico
</template>
<script>
import navigation from "@/components/app-common/app-v-navigation/app-v-navigation";
export default {
  name: "Post",
  components: {
    navigation
  },
  data() {
    return {
      tags: [
        {
          cat: "Категория",
          info: "Советы исполнителям"
        },
        {
          cat: "Время чтения",
          info: "2 минуты"
        }
      ],
      share: [
        {
          ico: "fb",
          classname: "post__share-link_fb"
        },
        {
          ico: "telegram",
          classname: "post__share-link_tg"
        },
        {
          ico: "inst",
          classname: "post__share-link_inst"
        },
        {
          ico: "vk",
          classname: "post__share-link_vk"
        },
        {
          ico: "twitter",
          classname: "post__share-link_twitter"
        },
        {
          ico: "ok-ru",
          classname: "post__share-link_ok"
        }
      ],
      links: [
        {
          type: "all",
          name: "News",
          title: "Все новости"
        },
        {
          type: "freelancers",
          name: "News",
          title: "Советы исполнителям"
        },
        {
          type: "customers",
          name: "News",
          title: "Советы заказчикам"
        },
        {
          type: "news",
          name: "News",
          title: "Новости сервиса"
        }
      ]
    };
  }
};
</script>
<style lang="scss">
@import "./post.scss";
</style>
