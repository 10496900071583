<template lang="pug">
  section.register-box.section
    ._wrap.wrap
      register-btn(type="customer")
      register-btn(type="freelancer")
</template>

<script>
import RegisterBtn from "./register-btn/register-btn";
export default {
  name: "register-box",
  components: { RegisterBtn }
};
</script>

<style lang="scss" scoped>
@import "register";
</style>
