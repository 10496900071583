<template lang="pug">
form.form(@submit.prevent="register")
  app-loading(v-if="loading")
  template(v-if="!status")
    ._tab
        p._tab-subtitle В качестве кого вы хотите зарегистрироваться?
        ._tab-list
          button._tab-item(type="button" :class="{active: userType === 'customer'}" @click="userType = 'customer'") Заказчик
          button._tab-item(type="button" :class="{active: userType === 'freelancer'}" @click="userType = 'freelancer'") Исполнитель
  
    ._group
      label(for="name")._label Ваше имя
      ._group-error(v-if="$v.name.$anyError") Введите ваше имя, оно не может быть короче 3-х символов
      input._input#name(type="text" v-model="name" placeholder="Например: Василий Иванов")
    
    ._group
      label(for="email")._label Ваш email
      ._group-error(v-if="$v.email.$anyError") Введите верный email
      input._input#email(type="text" v-model="email" placeholder="user@example.com")
  
    ._group
      label(for="phone")._label Ваш номер телефона, он не может быть короче 11 символов
      ._group-error(v-if="$v.phone.$anyError") Введите ваш номер телефона
      input._input#phone(type="text" v-model="phone" placeholder="+7 (___) ___ __-__")
  
    ._group
      label(for="password")._label Пароль
      ._group-error(v-if="$v.password.$anyError") Введите пароль, он не может быть короче 8 символов
      input._input#password(type="password" v-model="password" placeholder="********")
  
    button(type="submit").btn.-blue-darken._send Зарегистрироваться
    ._footer
      span._message Уже есть аккаунт?
      router-link(:to="{name: 'Auth', params: { type: 'login' }}" tag="a")._link Авторизация
  ._success-register(v-else) Вы успешно зарегистрировались!
    router-link(:to="{name: 'Home'}").btn.-blue._success-register-btn Вернуться на главную
</template>

<script>
import { required, minLength, email } from "vuelidate/lib/validators";
import UsersModel from "@/models/users.model";

const USERS = new UsersModel();

export default {
  name: "register-form",
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      password: "",
      userType: "customer",
      loading: false,
      status: false,
      referral: ""
    };
  },
  computed: {
    userData: state => ({
      name: state.name,
      email: state.email,
      password: state.password,
      phone: state.phone,
      type: state.userType,
      referral: state.referral
    })
  },
  validations: {
    name: {
      required,
      minLength: minLength(3)
    },
    email: {
      required,
      email
    },
    phone: {
      required,
      minLength: minLength(11)
    },
    password: {
      minLength: minLength(8)
    }
  },
  created() {
    if (this.$route.params.user) {
      this.userType = this.$route.params.user;
    }
    if (localStorage.referral) {
      this.referral = localStorage.referral;
    }
  },
  methods: {
    async register() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }

      this.loading = true;

      await USERS.create(this.userData).then(async r => {
        setTimeout(async () => {
          this.loading = false;
          this.status = true;
          localStorage.token = r.data.token;
          localStorage.refreshToken = r.data.refreshToken;
          USERS.setToken = r.data.token;
          await USERS.getMyUser().then(r => {
            const response = r.data;
            if (response.id) {
              this.$store.dispatch("user/UPDATE_DATA", response);
            }
            this.$router.push({ name: "Home" });
          });
        }, 1000);
      });
    }
  },
  watch: {
    userType(val) {
      this.$router.push({
        name: "Auth",
        params: { type: "register", user: val }
      });
    }
  }
};
</script>

<style src="./form.scss" lang="scss" scoped></style>
