<template lang="pug">
  .app-select
    ._current(@click="status = !status")
      ._current-value {{ currentValue }}
      icon(name="chevron-down" component="common")._chevron-icon
    vue-custom-scrollbar._list(:class="{active: status}" v-if="status")
      ._item(v-for="item in list" :key="item.id" @click="changeCurrentValue(item.name)" :class="{active: item.name === currentValue}") {{ item.name }}
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";

export default {
  name: "app-select",
  components: {
    vueCustomScrollbar
  },
  props: {
    currentValue: {
      type: String
    },
    model: {
      type: String
    },
    list: {
      type: Array
    }
  },
  data() {
    return {
      value: "",
      status: false
    };
  },
  methods: {
    changeCurrentValue(value) {
      this.$emit("changeModel", value, this.model);
      this.status = false;
    }
  }
};
</script>

<style lang="scss">
@import "app-select";
</style>
