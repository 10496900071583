<template lang="pug">
router-link(:to="{name: 'Auth', params: {type: 'register', user: type}}").register-btn
  span._name {{ text }}
  icon(:name="type" component="register")._icon  
</template>

<script>
export default {
  name: "register-btn",
  props: {
    type: {
      require,
      default: "hi"
    }
  },
  data() {
    return {
      types: {
        customer: "Зарегистрироваться в качестве заказчика",
        freelancer: "Зарегистрироваться в качестве исполнителя"
      }
    };
  },
  computed: {
    text: state => state.types[state.type]
  }
};
</script>

<style lang="scss">
@import "register-btn";
</style>
