<template lang="pug">
  .page-container
    post
</template>
<script>
import post from "@/components/news/post/post.vue";
export default {
  name: "Post",
  components: {
    post
  }
};
</script>
