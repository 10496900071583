<template lang="pug">
  components(:is="layout")
</template>

<script>
import appLayoutVue from "./layouts/app-layout.vue";
import API from "@/API";

export default {
  components: { appLayoutVue },
  computed: {
    layout: state =>
      state.$route.meta.layout ? state.$route.meta.layout : "appLayoutVue"
  },
  async created() {
    if (API.instance.token) {
      API.instance.getMyUser().then(r => {
        const response = r.data;
        if (response.id) {
          this.$store.dispatch("user/UPDATE_DATA", response);
        }
      });
    }
  }
};
</script>

<style src="@/styles/_main.scss" lang="scss"></style>
