import Vue from "../../main";
export default async function customer({ to, store, nextMiddleware }) {
  setTimeout(() => {
    if (store.getters["user/group"] === "customer") {
      return nextMiddleware();
    }
    localStorage.redirect = to.name;
    Vue.$toasts.warning("Это действие разрешено только для заказчиков", {
      type: "warning"
    });
    Vue.$router.push({ name: "Auth" });
  }, 100);
}
