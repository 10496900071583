<template lang="pug">
    #page
        h2 Пользовательское соглашение
        h4 1. Введение
        p 1.1 Пользовательское Соглашение вступает в силу с момента выражения вашего согласия с его условиями в момент регистрации в World of job: путем нажатия вами на странице регистрации в World of job кнопки «Зарегистрироваться».
        p 1.2 Соглашаясь с ПС, вы подтверждаете, что все документы, составленные посредством программно-аппаратного комплекса World of job, а также подтвержденные вами электронные соглашения и регламенты системы World of job, признаются вами полностью, как если бы указанные документы были составлены и подписаны вашей личной подписью в простой письменной форме.
        p 1.3 Принимая ПС, вы признаёте, что совершенные вами действия в World of job по передаче и получению расчётных единиц посредством программно-аппаратного комплекса World of job и зафиксированные им в электронном виде, являются вашим волеизъявлением на установление, изменение и прекращения правоотношений по заключению и совершению сделок, в том числе по передаче исключительных прав на объекты интеллектуальной собственности, и могут быть использованы для подтверждения заключенных и совершенных вами сделок, в том числе в качестве доказательств в судебных органах, при рассмотрении споров по гражданско-правовым сделкам, подтверждаете их действительность, юридическую и исковую силу. 
        p 1.4 Вы принимаете факт и согласны, что ПС может быть изменено Администрацией в одностороннем порядке без специального уведомления. 
        p 1.5 Новая редакция ПС вступает в силу по истечении 3 (трех) дней с момента ее публикации, если иное не предусмотрено новой редакцией ПС. Действующая редакция ПС всегда находится на странице "Пользовательское соглашение"

        h4 2. Определения и термины
        p Список терминов и сокращений применяемых в Пользовательском соглашении:
        p 2.1 URL - адрес страницы в интернете. Отображается в адресной строке браузера. Например, URL главной страницы World of job выглядит следующим образом: https:// Worldofjob.ru
        p 2.2 Автор - пользователь World of job, принимающий Заказы Заказчиков в работу и выполняющий их на условиях данного ПС и Заказчика, либо размещающий статьи в Магазине статей на условиях данного ПС. 
        p 2.3 World of job - совокупность онлайн сервисов расположенных по адресу https:// Worldofjob.ru и направленная на предоставление услуг в области копирайтинга, рерайтинга, переводов, коррекции документов, поиска информации, рекламных услуг и т.п. 
        p 2.4 Администрация - сотрудники World of job, осуществляющие техническую поддержку и контроль работы World of job. 
        p 2.5 Аккаунт - это учетная запись Пользователя, создается в момент регистрации Пользователя. Учетная запись позволяет получить доступ к своей персональной странице.
        p 2.6 Арбитраж - возможность в World of job разрешения споров обращением спорящих сторон к Администрации, выполняющей роль Арбитра. 
        p 2.7 Блокировка аккаунта - частичное либо полное приостановление доступа Пользователю к использованию сервисов World of job.
        p 2.8 Заказчик - Пользователь размещающий Заказы в World of job. 
        p 2.9 Заказ - задание, направленное на выполнение работы или предоставление услуг, которое размещает Заказчик на своих условиях, не противоречащих ПС и действующим локальному и международному законодательствам. Заказы разделяют по типам работы:
        p 2.11 Приглашение пользователей - рассылка приглашений на вступление в группы в социальных сетях, сообществах и т.д.;
        p 2.12 Логин - уникальный идентификатор пользователя системы, которое присваивается пользователю в момент регистрации. Логин соответствует полю «e-mail» в Форме регистрации.
        p 2.13 Модерация заказа - действия Заказчика по проверке выполненной Автором работы, в результате которых работа может быть оплачена, возвращена на доработку либо мотивированно отклонена.
        p 2.14 Мошенничество - деятельность Пользователя, нарушающая ПС и направленная на получение средств в World of job обманным путем, сдача заказчику в выполненной работе текста не соответствующего заказу, неоплата полученных от Авторов работ и т.п. 
        p 2.15 Ник (псевдоним) - уникальное имя пользователя системы, которое выбирается пользователем самостоятельно в момент регистрации. Ник используется для идентификации Пользователя другими Пользователями и указывается при осуществлении любых публичных действий Пользователя в World of job.
        p 2.16 Партнер - Пользователь, сотрудничающий с World of job в рамках Партнерской программы.
        p 2.17 Партнерская программа - программа поощрения Пользователя за финансовые транзакции Рефералов, в рамках которой выплачивается партнерское вознаграждение в соответствии с Регламентом Партнерской программы World of job.
        p 2.18 Партнёрское вознаграждение - вознаграждение, которое начисляется в рамках Партнерской программы исключительно за счёт средств World of job.
        p 2.19 Перевод - деятельность, направленная на интерпретацию смысла текста на одном языке и созданию нового, эквивалентного текста на другом языке.
        p 2.20 Пользователь - физическое или юридическое лицо, прошедшее процедуру регистрации в World of job и получившее уникальные логин и пароль.
        p 2.21 ПС - данное Пользовательское соглашение регулирующее взаимодействие между World of job и Пользователем.  
        p 2.22 Работа – задача, задание, сформулированное заказчиком в соответствии с требованиями, предъявляемыми к выполнению.
        p 2.23 Спам - массовая рассылка коммерческой, политической и иной информации или иного вида сообщений лицам, не выражавшим желания их получать.
        p 2.24 Форма регистрации - анкетная форма необходимая для регистрации Пользователей и получения персонализированного доступа в World of job.

        h4 3. Общие положения
        p 3.1 World of job предоставляет свои услуги на условиях, являющихся предметом настоящего ПС.
        p 3.2 ПС может быть изменено Администрацией в одностороннем порядке без специального уведомления, новая редакция ПС вступает в силу по истечении 3 (трех) дней с момента ее публикации, если иное не предусмотрено новой редакцией ПС. Действующая редакция ПС всегда находится на странице "Пользовательское соглашение". Предыдущие редакции ПС размещаются на странице "Архив пользовательских соглашений"
        p 3.3 Пользователь вправе отвергнуть дополнительные внесения либо изменения в настоящее ПС, производимые World of job, что означает отказ Пользователя от услуг World of job.
        p 3.4 ПС является юридически обязывающим договором между Пользователем и Администрацией World of job и регламентирует использование сервисов World of job.
        p 3.5 Помимо ПС с пользователями могут быть заключены соответствующие договоры и возложены дополнительные обязательства, связанные с использованием сервисов World of job, других сервисов, а также контента или программного обеспечения, принадлежащего третьей стороне.
        p 3.6 Все новости системы, являются неотъемлемой частью данного Пользовательского Соглашения. Соглашаясь с данным ПС, вы также соглашаетесь со всеми изменениями и нововведениями, описанными в разделе новости, а также обязуетесь следовать данным правилам и принимаете ответственность за нарушение данных правил, установленную ПС.
        p 3.7 Правила форума, опубликованные Администрацией в различных ветках форума, являются неотъемлемой частью данного Пользовательского Соглашения. Соглашаясь с данным ПС, вы соглашаетесь со всеми изменениями и нововведениями, описанными в разделе новости, а также обязуетесь следовать данным правилам и принимаете ответственность за нарушение данных правил, установленную ПС.
        p 3.8 World of job предоставляет Пользователям доступ к ряду онлайн сервисов, размещение заказов, продажу статей, поиск доступных к выполнению заказов и возможность их выполнения, контроль за выполнением Заказов, Модерация Заказов, поиск и размещение информации и т.д. Все существующие на данный момент сервисы, а также любое развитие их и/или добавление новых является предметом настоящего ПС. 
        p 3.9 В соответствии с данным ПС World of job предоставляет Пользователям право использовать программное обеспечение, предоставляемое в сервисах, на одном компьютере, на условиях неисключительной лицензии, при условии, что Пользователь и любые иные лица при содействии со стороны Пользователя не будут копировать или изменять программное обеспечение; 
        p 3.10 Сервисы World of job предоставляются Пользователю «как есть» на собственный риск Пользователей. Администрация не принимает на себя никакой ответственности, в том числе и за соответствие сервисов целям Пользователя; 
        p 3.11 Администрация не гарантирует, что:
        p 3.11.1 сервисы будут соответствовать требованиям Пользователей;
        p 3.11.2 сервисы будут предоставляться непрерывно, быстро, надежно и без ошибок;
        p 3.11.3 результаты, которые могут быть получены с использованием сервисов, будут точными и надежными;
        p 3.11.4 качество какого-либо продукта, услуги, информации и пр., полученного с использованием сервисов World of job, будет соответствовать ожиданиям Пользователей;
        p 3.11.5 все ошибки в программном обеспечении World of job будут исправлены;
        p 3.12 Любые материалы, полученные Пользователем с использованием сервисов World of job, могут быть использованы Пользователем на свой собственный страх и риск; 
        p 3.13 В соответствии с ПС World of job предоставляет следующие услуги Пользователям:
        p 3.13.1 Исключительно посреднические услуг между Пользователями World of job в области переводов, коррекции документов, поиска информации, рекламных услуг и т.д., а именно предоставляет необходимый функционал для возможности использования заявленных в World of job сервисов.   
        p 3.14 Для использования World of job, необходимо иметь специальное техническое устройство (компьютер, ноутбук, КПК, мобильный телефон с необходимым функционалом и т.п.) и доступ в Интернет. Все вопросы приобретения прав доступа в Сеть, покупки и наладки для этого соответствующего оборудования и программных продуктов решаются вами самостоятельно и не попадают под действие ПС.
        p 3.15 Для получения доступа и возможности использования персонализированных сервисов World of job Вам необходимо пройти процедуру регистрации и получить уникальные логин и пароль.
        p 3.16 Для регистрации необходимо иметь действующий e-mail адрес.
        p 3.17 В случае предоставления заведомо ложной, неверной информации или, если у World of job есть серьезные основания полагать, что предоставленная информация неверна, неполна или неточна, World of job сохраняет за собой право приостановить либо отменить регистрацию и отказать в использовании своих сервисов (либо их частей). 
        p 3.18 Право осуществить регистрацию и получить доступ к персонализированным сервисам World of job доступно только один раз.
        p 3.19 В случае обнаружения попыток неоднократной регистрации World of job сохраняет за собой право приостановить либо отменить регистрацию и отказать в использовании своих сервисов (либо их частей).  
        p 3.20 Если в течении года Пользователем не было произведено ни одной авторизации в Систему, то Администрация устанавливает Пользователю Статус неактивного Пользователя.
        p 3.21 В случае жалоб Пользователей, до выяснения обстоятельств, Администрация может приостановить действие вашего Аккаунта или некоторых функций World of job без объяснения причин.
        p 3.22 Правом получить персонализированный доступ к World of job и пользоваться сервисами World of job обладает любое физическое лицо, не моложе 18 лет, либо юридическое лицо. 
        p 3.23 Под физическим лицом понимается гражданин или лицо без гражданства, обладающие достаточной правосубъектностью для использования сервисов World of job.  
        p 3.24 Юридическое лицо осуществляет процедуру регистрации и в дальнейшем использует сервисы World of job через наделенное необходимыми полномочиями лицо. 
        p 3.25 Форма регистрации может быть изменена Администрацией в одностороннем порядке, в таком случае при первом посещении World of job после изменения Формы регистрации Пользователю будет предложено привести свои данные указанные в Форме регистрации в соответствие с внесенными изменениями. В случае отказа Пользователь получит ограниченный доступ к World of job.
        p 3.26 По завершении процесса регистрации Пользователю предоставляется логин и пароль для доступа к персонализированной части World of job. 
        p 3.27 Пользователь несет персональную ответственность за безопасность своего логина и пароля.
        p 3.28 Каждое лицо, которое идентифицируется, введя правильный логин и пароль, считается со стороны World of job «законным» пользователем и все действия в аккаунте Пользователя, для которых, при входе в World of job, логин и пароль были введены правильно, рассматриваются как действия совершенные данным Пользователем и Администрация за данные действия ответственности не несет.  
        p 3.29 Для завершения работы под своим паролем в World of job необходимо нажать кнопку «выход» по окончании каждой сессии работы в World of job. World of job не несет ответственности за возможную потерю или порчу данных, которая может произойти из-за нарушения данного положения ПС.   
        p 3.30 За контент, размещенный в World of job, несет ответственность то лицо, которое этот контент произвело. Это означает, что именно Пользователь, а не World of job, полностью отвечает за весь контент, который им загружается, посылается, передается или каким-либо другим способом делается доступным с помощью World of job.   
        p 3.31 Администрация World of job, перед публикацией заказа для доступа Авторам, осуществляет предварительную проверку заказа на соответствие ПС. Проверка заказа осуществляется не более, чем 1 рабочий день, не считая дня размещения заказа Заказчиком.
        p 3.32 World of job не контролирует контент, передаваемый через его сервисы, и не гарантирует точность, полноту или качество этого контента.
        p 3.33 Используя сервисы World of job, вы можете получить контент, который является оскорбительным, недостойным или спорным. Ни при каких обстоятельствах World of job не несет ответственности за контент, созданный его Пользователями. 
        p 3.34 В World of job категорически запрещены, вплоть до блокировки аккаунта следующие действия: 
        p 3.34.1 загрузка, передача или любой другой способ размещения контента, который является незаконным, недостоверным, заведомо ложным, вредоносным, угрожающим, оскорбляет нравственность, пропагандирует ненависть и/или дискриминацию людей по расовому, этническому, половому, религиозному, социальному признакам, содержит оскорбления в адрес конкретных лиц или организаций;
        p 3.34.2 загрузка, передача или размещение любым другим способом фотографий, исключительные авторские права на которые принадлежат третьим лицам.
        p 3.34.3 загрузка, передача или размещение любым другим способом контента, который не соответствует требованиям World of job к уникальности текста, нарушает авторские права, права интеллектуальной собственности, патентные права, права на товарный знак, коммерческую тайну, копирайт или прочие имущественные и неимущественные права и/или авторские и смежные с ним права третьей стороны; 
        p 3.34.4 публикация и провокация к публикации личных данных в сервисах World of job, таких как телефонный номер, адрес электронной почты, номер электронного кошелька, учетные записи ICQ и Skype и т.п., в т.ч. в заказах, статьях, форуме, комментариях к заказу и т.д. 
        p 3.34.5 публикация и провокация к публикации ссылок на блоги и сайты, посвященные копирайтингу, ресурсы, предоставляющие сервисы, схожие с сервисами World of job, а также прочих данных о таких ресурсах. Например, биржи контента, магазины статей, сервисы рефератов и контрольных работ, биржи фриланса, другие биржи по выполнению заданий пользователями, форумы о работе в Интернете и др. 
        p 3.34.6 нанесение оскорблений и провокация к нанесению оскорблений в сервисах World of job, в т.ч. в форуме, комментариях к заказу, статьях и т.д. 
        p 3.34.7 Флуд в сервисах World of job, в т.ч. в форуме, комментариях к заказу, статьях и т.д.
        p 3.34.8 нарушающие права несовершеннолетних лиц и/или причиняющие им вред в любой форме;
        p 3.34.9 ущемляющие права меньшинств; 
        p 3.34.10 выдавание себя за другого человека или представителя организации и/или сообщества без достаточных на то прав, в том числе за сотрудников World of job, за модераторов форумов, за владельца сайта, а также введения в заблуждение относительно свойств и характеристик каких-либо субъектов или объектов; 
        p 3.34.11 загрузка, посылка, передача или любого другого способа размещение Контента, который Пользователь не имеет права делать доступным в соответствии с действующим законодательством, локальными нормативными актами или согласно каким-либо контрактным отношением;
        p 3.34.12 загрузка, посылка, передача или любым другим способом размещение рекламной информации, спама (в том числе и поискового), списков чужих адресов электронной почты, схем «пирамид», многоуровневого (сетевого) маркетинга (MLM), систем интернет-заработка и e-mail-бизнесов, «писем счастья», упоминаний и ссылок на ресурсы, посвященные благотворительности или сбору денежных средств, а также для участия в этих мероприятиях (отправка несогласованных писем со ссылками на предоставляемые World of job сервисы, включая почтовые адреса, сайты, закладки и т.п.), даже если отправка производилась без непосредственного использования почтовых серверов World of job;  
        p 3.34.13 загрузка, посылка, передача и размещение любого контента, связанного с нарушением, уничтожением либо ограничением функциональности любого компьютерного или телекоммуникационного оборудования или программ, получением несанкционированного доступа к платным ресурсам в интернете либо защищенной и закрытой от общего доступа информации, описанием ПО или скриптов, предназначенных для использования в злоумышленных целях, в т.ч. содержащего вирусы или другие компьютерные коды, файлы или программы, а также серийные номера к коммерческим программным продуктам и программы для их генерации, логины, пароли, инструкции и прочие средства для получения несанкционированного доступа к платным ресурсам в Интернете, а также размещения ссылок на вышеуказанную информацию;
        p 3.34.14 сбор и хранение персональных данных третьих лиц без соответствующего на то разрешения и/или согласия третьих лиц;
        p 3.34.15 применение любых форм и способов незаконного представительства других лиц в Интернет;
        p 3.34.16 незаконная деятельность, преследуемая по локальному законодательству и нормам международного права, в т.ч. мошенничество, пособничество террористическим организациям, легализация (отмывание) незаконно приобретенного имущества, в т.ч. денежных средств;
        p 3.34.17 умышленное нарушение локального законодательства или норм международного права, как с умыслом, так и без;
        p 3.34.18 нарушение лицензионных и пользовательских соглашений сторонних сервисов, в том числе работающих по системе pay-per-click (оплата за нажатие) таких как Google Adsense, Yandex.Direct и других партнерских и реферальных программ сторонних сервисов, а также загрузка, передача или размещение любым другим способом в World of job любых реферальных ссылок партнерских программ;
        p 3.34.19 нарушение нормальной работы World of job;
        p 3.34.20 размещение ссылок на Интернет ресурсы, содержание которых противоречит действующему локальному законодательству; 
        p 3.34.21 содействие действиям, направленным на нарушение ограничений и запретов, налагаемых ПС.
        p 3.34.22 воспроизведение, повторение и копирование, продажа и перепродажа, а также использование для каких-либо коммерческих целей какие-либо части сервисов World of job, использование сервисов World of job или доступ к ним, кроме тех случаев, когда такое разрешение дано World of job в письменном виде.  
        p 3.34.23 создавать программы, производные от программного обеспечения World of job;
        p 3.34.24 проникать в программное обеспечение World of job с целью получения кодов программ;
        p 3.34.25 осуществлять продажу, уступку, сдачу в аренду, передачу третьим лицам в любой иной форме прав в отношении программного обеспечения World of job, служб World of job, предоставленных по ПС, а также модифицировать сервисы World of job, в том числе с целью получения  несанкционированного доступа к ним;   
        p 3.34.26 публично обсуждать действия Администрации, связанные с решениями по нарушениям Пользовательского Соглашения, в том числе с блокировкой аккаунтов пользователей, определением вины пользователей за те или иные нарушения и прочие;
        p 3.34.27 создавать заказ не предназначенный для авторов, а также заказ, в тексте которого заказчик заранее утверждает, что такой заказ не будет оплачен;
        p 3.34.28 регистрация по партнерской программе аффилированных аккаунтов , в т.ч. клонов, супруга, родственников, временно или постоянно проживающих по одному адресу и пр. Такие аккаунты не участвуют в партнерской программе;
        p 3.34.29 поиск и привлечение рефералов по партнерской программе через рекламные сети и рекламные сервисы, с использованием в рекламных материалах слов " World of job ", а также партнерской ссылки на сайт World of job. Такие аккаунты не участвуют в партнерской программе;
        p 3.34.30 загрузка в качестве аватара изображений, содержащих текстовые надписи.
        p 3.35 Технология работы World of job может потребовать передачи контента по компьютерным сетям, а также изменения его для соответствия техническим требованиям. 
        p 3.36 Сервисы World of job и все необходимые программы, связанные с ними, содержат конфиденциальную информацию, которая защищена законами об интеллектуальной собственности и прочими локальными и международными законами, а контент, предоставляемый в процессе использования служб, защищен авторскими правами, торговыми марками, патентами и прочими соответствующими законами. Кроме случаев, специально оговоренных World of job вы соглашаетесь не продавать, не распространять этот контент и программы, целиком либо по частям.  
        p 3.37 Персональные данные пользователей World of job обрабатываются в соответствии с политикой конфиденциальности World of job, которая является неотъемлемой частью данного ПС.  
        h4 4. Права и обязанности сторон
        p 4.1 Права и обязанности Администрации:
        p 4.1.1 Администрация обязуется обеспечивать работоспособность World of job, гарантируя тем самым предоставление посреднических услуг Пользователям.
        p 4.1.2 Администрация обязуется осуществить регистрацию Пользователя, и предоставить доступ к услугам World of job. 
        p 4.1.3 Администрация вправе запретить использование определенных логинов или изъять их из обращения. 
        p 4.1.4 Администрация обязуется своевременно зачислять средства Пользователей перечисленные в World of job в соответствии с ПС.
        p 4.1.5 Администрация вправе вносить дополнения, улучшения и изменения в работу действующих онлайн сервисов. 
        p 4.1.6 Администрация вправе осуществлять рассылку Пользователям и направлять информационные сообщения, в т.ч. о внесенных изменениях в работу World of job, посредством электронной почты на адрес указанный Пользователем при регистрации. В случае необходимости Пользователи могут отписаться от рассылки убрав галочку напротив пункта "Получать сообщения от администрации на E-mail" в разделе "Личные данные". 
        p 4.1.7 Администрация обязуется оперативно предпринимать все возможные действия для устранения возникших неисправностей в работе World of job.
        p 4.1.8 Администрация не гарантирует постоянного и бесперебойного доступа к онлайн сервисам World of job в связи с возможными нарушениями по причине действия непреодолимых сил и иных обстоятельств, предотвращение которых неподвластно Администрации.  
        p 4.1.9 Администрация не несет ответственности ни за какие задержки, сбои, неверную или несвоевременную доставку, удаление или несохранность какой-либо пользовательской персональной информации.
        p 4.1.10 Администрация обязуется осуществлять модерацию заказа не более 1 рабочего дня, не считая дня размещения заказа на модерацию.
        p 4.1.11 Администрация обязуется осуществлять модерацию статей добавленных в Магазин статей не более 3-х рабочих дней, не считая дня размещения статьи на модерацию в Магазин статей.
        p 4.1.12 Администрация обеспечивает предоставление посреднических услуг в World of job и не гарантирует уникальность и сохранность авторских прав на выполненные в World of job работы и размещенные статьи.
        p 4.1.13 Администрация вправе частично или полностью прерывать предоставление услуг World of job, в связи с техническими работами по улучшению либо исправлению работоспособности World of job. 
        p 4.1.14 Администрация не обеспечивает своевременность выполнения заказов и не несет материальной, в т.ч. в виде компенсации, и какой либо еще ответственности в связи с упущенной Пользователями выгодой и недополученной прибылью.
        p 4.1.15 Администрация обязуется контролировать соблюдение Пользователями условий ПС и оперативно предпринимать меры по пресечению выявленных нарушений.
        p 4.1.16 Администрация обязуется своевременно осуществлять вывод средств по заявкам Пользователей.
        p 4.1.17 Администрация вправе отклонить заявку Пользователя на вывод, в случае нарушения последним ПС.
        p 4.1.18 Администрация вправе блокировать или ограничить доступ Пользователя к World of job, в связи с нарушением ПС, а также направить информационное сообщение о деятельности такого Пользователя в другие сервисы. 
        p 4.1.19 Администрация вправе направлять претензии и извещения Пользователю, в случае нарушения последним условий ПС.
        p 4.1.20 Администрация вправе удержать средства Пользователя, начисленные в системе, в связи с нарушением последним ПС.
        p 4.1.21 Администрация вправе принимать оперативные решения по жалобам Пользователей или спорам между Пользователями. Результатом таких решений может быть: блокировка аккаунта Пользователя, возврат средств за счет Пользователя нарушившего ПС.
        p 4.1.21.1 Администрация обязуется при арбитражном рассмотрении споров Пользователей быть беспристрастной и принимать непредвзятые решения по существу спора.
        p 4.1.22 Администрация не обязана просматривать контент любого вида перед размещением.
        p 4.1.23 Администрация вправе по своему усмотрению отказать в размещении или удалить любой контент размещенный в World of job.
        p 4.1.24 Администрация вправе останавливать заказы не соответствующие действующим условиям ПС World of job. 
        p 4.1.25 Администрация обязуется осуществлять привлечение новых Пользователей.
        p 4.1.26 Администрация вправе доводить до сведения Пользователей (Заказчиков и Авторов) условия размещения информационных материалов;
        p 4.1.27 Администрация обязуется вести лицевые счета Пользователей и фиксировать информацию о платежах и расходах Пользователей за предоставленные услуги, с предоставлением данной информации Пользователям.
        p 4.1.28 Администрация обязуется вести учет средств перечисляемых между Пользователями за услуги полученные с использованием сервисов World of job.
        p 4.1.29 Администрация обязуется предоставлять Пользователям персональную статистическую информацию об объеме и стоимости предоставленных услуг. 
        p 4.1.30 Администрация обязуется соблюдать конфиденциальность информации Пользователей, за исключением случаев предоставления такой информации в прокуратуру, полицию, суд и другие государственные органы по письменному требованию.
        p 4.1.31 Администрация вправе запретить автоматическое обращение к своим сервисам, а также прекратить прием любой информации, сгенерированной автоматически (например, текст созданный синонимайзером, почтового спама).
        p 4.1.32 Администрация вправе удалить аккаунты пользователей, которые не использовали свой аккаунт в течение долгого времени, при отсутствии средств на счёте в World of job.
        p 4.1.33 Администрация вправе поменять условия ПС (правила и ограничения) в любое время, без предварительного уведомления Пользователей.  
        p 4.1.34 Администрация вправе устанавливать ограничения в использовании сервисов, в том числе: срок хранения любого контента.
        p 4.1.35 Администрация вправе автоматически останавливать заказы, по которым не было оплачено ни одной работы в течение 3-х месяцев, с момента оплаты последней работы, а в случае отсутствия оплаченных работ по заказу, с момента последнего изменения заказа.
        p 4.1.36 Администрация обязуется оперативно реагировать на жалобы Пользователей и предпринимать все возможные меры для их удовлетворения.
        p 4.1.37 Администрация оставляет за собой право прекратить действие аккаунта любого Пользователя без объяснения причин.
        p 4.1.38 Администрация оставляет за собой право, в случае поступления жалобы Исполнителя на необоснованный отказ в оплате выполненной работы, принудительно оплатить работу, по которой Заказчик необоснованно отказал в оплате.
        p 4.1.39 Администрация оставляет за собой право, в случае поступления жалобы Пользователя на приобретение в Магазине Статей неуникального или некачественного контента, списать с Автора такой статьи полученные за продажу статьи средства и зачислить их Пользователю, который обратился с жалобой.
        p 4.1.40 Администрация оставляет за собой право, в случае поступления жалобы Заказчика на оплату неуникальной и/или некачественной работы, списать с Автора такой работы полученные от Заказчика средства и зачислить их Заказчику, который обратился с жалобой.
        p 4.1.41 Администрация оставляет за собой право, в случае поступления жалобы на Пользователя, отказать Пользователю в выводе средств.
        p 4.1.42 Администрация вправе организовать и провести бонус-программу среди Пользователей.
        p 4.2 Права и обязанности Пользователей:
        p 4.2.1 Пользователь обязуется при регистрации предоставить правдивую, точную и полную информацию о себе, заполнив Форму Регистрации.
        p 4.2.2 Пользователь обязуется поддерживать личную информацию в актуальном состоянии.
        p 4.2.3 Пользователь обязуется зарегистрировать только один аккаунт, если иное не предусмотрено ПС.
        p 4.2.4 Пользователь обязуется соблюдать условия ПС, в т.ч. установленные новостями и правилами форума, локального и международного законодательства при работе в World of job.
        p 4.2.5 Пользователь обязуется не публиковать свои личные контактные данные, такие как телефонный номер, адрес электронной почты, номер электронного кошелька, учетные записи ICQ, Skype и т.п. 
        p 4.2.6 Пользователь обязуется руководствоваться нормами морали и обычаями деловой этики при использовании сервисов World of job, в т.ч. общаясь в форуме.
        p 4.2.7 Пользователь обязуется незамедлительно обратиться с письменной жалобой в Администрацию World of job, в случае обнаружения кражи его логина и пароля третьими лицами, а также в любом случае неавторизованного (не разрешенного Пользователем) доступа к своему аккаунту в World of job со своим логином и паролем и/или о любом нарушении безопасности.  
        p 4.2.8 Пользователь вправе пополнять свой счет в World of job.  
        p 4.2.9 Пользователь вправе осуществлять вывод своих средств из World of job. 
        p 4.2.10 Пользователь вправе пользоваться сервисами World of job в полном объеме по собственной надобности, не нарушая ПС. 
        p 4.2.11 Пользователь вправе направлять жалобы, просьбы, предложения, обращения к Администрации. 
        p 4.2.12 Пользователь обязуется извещать Администрацию обо всех технических неполадках World of job.
        p 4.2.13 Пользователь обязуется извещать Администрацию обо всех нарушениях ПС.
        p 4.2.14 Пользователь вправе отвергнуть дополнительные внесения изменений в ПС, что означает отказ Пользователя от услуг World of job. 
        p 4.2.15 Пользователь обязуется не передавать свой логин, пароль и права по настоящему ПС третьим лицам без письменного согласия World of job. 
        p 4.2.16 Пользователь обязуется самостоятельно оценивать все риски, связанные с использованием Контента, включая оценку надежности, полноты или полезности этого контента.
        p 4.2.17 Пользователь вправе обратиться в Администрацию в течении 7 дней с момента наступления действия или события, по факту которого он обращается.
        p 4.2.18 Пользователи обязуются не разглашать конфиденциальную информацию, полученную от администрации в рамках предоставления пользователю услуг World of job в соответствии с настоящим ПС. Под конфиденциальной информацией понимается, переданная сторонами друг другу посредством ЛПА и другими, защищенными от третьих лиц, способами передачи данных в World of job, любая информация, в том числе: научно-техническая, технологическая, коммерческая, организационная, персональная или иная информация, имеющая потенциальную ценность в силу ее неизвестности третьим лицам, к которой нет свободного доступа на законном основании, и по отношению к которой принимаются меры охраны.  
        p 4.3 Права и обязанности Заказчиков:
        p 4.3.1 Заказчик несет все права и обязанности Пользователя.
        p 4.3.2 Данное ПС для Заказчика является договором-поручением заключаемым посредством World of job для выполнения заказов. По данному договору Заказчик вправе приобретать исключительные права в полном объеме на весь контент полученный в World of job законным способом, при использовании сервисов World of job, без нарушений ПС.  
        p 4.3.3 Заказчик обязуется руководствоваться ПС, локальным законодательством и международными нормами Авторского права при формировании заказов в World of job. 
        p 4.3.4 Заказчик вправе размещать заказы и самостоятельно определять стоимость вознаграждения за выполнение таких заказов.
        p 4.3.5 Заказчик обязуется подробно формулировать заказ, с четким указанием и описанием условий заказа.
        p 4.3.5.1 В одной работе Заказчик может оформить только одно задание, то есть требовать выполнение одного действия. Запрещено, вплоть до остановки Заказа, в одной работе совмещать два и более действий, которые можно выполнить по отдельности. Примеры совмещенных заданий - написать две (и более) статьи, заполнить десять товарных карточек, оказать не менее пяти консультаций и т.д.
        p 4.3.6 Заказчик обязуется не создавать фиктивные заказы, работы по которым не будут оплачены, а также персональные заказы, исполнителем в которых указан сам заказчик.
        p 4.3.7 Заказчик вправе указать срок выполнения заказа и срок проверки выполненной по заказу работы.
        p 4.3.8 Заказчик обязуется оплатить работу в течении срока проверки выполненной по заказу работы. В случае неоплаты работы, находящейся на проверке, в течении срока проверки, такая работа оплачивается автоматически.
        p 4.3.9 Заказчик вправе самостоятельно принимать решения по выполненным работам и использовать полученный в результате принятых им работ контент любым способом, не противоречащим данному ПС, локальному законодательству и международным нормам Авторского права.
        p 4.3.10 Заказчик вправе отказать в оплате за выполненную работу в случае превышения заданного объема работы более, чем на 10%, обнаружения в работе ошибок, опечаток, неуникального текста, текста низкого качества либо несоответствующего условиям заказа.
        p 4.3.11 Заказчик обязуется оплатить все работы, полученные по своему заказу, если такие работы удовлетворяют условиям этого заказа и ПС. Оплата осуществляется в размере, порядке и срок, установленном заказом Заказчика и настоящим ПС.
        p 4.3.12 Заказчик обязуется поддерживать доступность своих интернет-сайтов, на которых осуществляется размещение информационных материалов по созданным заказам.
        p 4.3.13 В случае отказа в оплате за работу Заказчик обязуется не использовать результат такой работы или часть результата такой работы в каких-либо целях, в т.ч. публиковать, перерабатывать, передавать третьим лицам. Все права на результат неоплаченной работы остаются у исполнителя и только исполнитель вправе распоряжаться этими результатами по своему усмотрению.
        p 4.3.14 Заказчик вправе прекратить использование сервисов World of job и осуществить возврат неиспользованных средств тем способом, каким эти средства были зачислены в World of job.  
        h4 5. Ответственности сторон
        p 5.1 Ответственность Администрации:
        p 5.1.1 Администрация не несет ответственности за любые прямые или непрямые убытки, произошедшие из-за использования либо невозможности использования сервисов World of job.
        p 5.1.2 Администрация не несет ответственности за любые прямые или непрямые убытки, произошедшие из-за несанкционированного доступа к вашим коммуникациям, вашему аккаунту третьими лицами.
        p 5.1.3 Администрация не несет ответственности за любые прямые или непрямые убытки, произошедшие из-за заявления или поведение любого третьего лица в сервисах World of job.
        p 5.1.4 Администрация не несет ответственности ни за какие задержки, сбои, неверную или несвоевременную доставку, удаление или несохранность какой-либо пользовательской персональной информации.
        p 5.1.5 Администрация не несет ответственности за весь контент, который загружается, посылается, передается или каким-либо другим способом делается доступным с помощью сервисов World of job.
        p 5.1.6 Администрация не контролирует контент, передаваемый через его сервисы, и, следовательно, не несет ответственность и не гарантирует точность, полноту или качество этого контента. 
        p 5.1.7 Ни при каких обстоятельствах World of job не несет ответственности за контент, созданный Пользователями его служб. 
        p 5.1.8 Администрация не несет ответственности за нарушение Пользователями условий ПС.
        p 5.1.9 Администрация не несет ответственности за качество каналов связи, с помощью которых осуществляется доступ к World of job. 
        p 5.2 Ответственность Пользователей:
        p 5.2.1 Пользователь принимает на себя полную ответственность и риски связанные с использованием Интернет.
        p 5.2.2 Пользователь несет ответственность за сохранность своего логина и пароля.
        p 5.2.3 За убытки причиненные третьими лицами в связи с несанкционированным использованием логина и пароля Пользователя несет полную ответственность Пользователь, которому данные логин и пароль присвоены.
        p 5.2.4 Пользователь несет ответственность за несоблюдение всех условий ПС, в т.ч. условий опубликованных в новостях World of job и правилах форума. 
        p 5.2.5 Пользователь несет ответственность за публикацию личных контактных данных.
        p 5.2.6 Пользователь несет ответственность за контент размещаемый им в World of job и передаваемый им другим Пользователям с помощью сервисов World of job. 
        p 5.2.7 Пользователь несет ответственность за ущерб причиняемый его действиями в World of job и с использованием сервисов World of job.  
        p 5.2.8 Пользователь несет ответственность за нарушение локального и международного законодательства.
        p 5.3 Ответственность Заказчиков
        p 5.3.1 На Заказчика полностью распространяется ответственность Пользователя (п. 5.2)
        p 5.3.2 Заказчик несет ответственность за невыполнение, несвоевременное выполнение своих обязательств по размещенным заказам.
        p 5.3.3 Заказчик несет ответственность за содержание своих заказов и соответствие данных заказов ПС.
        p 5.3.4 Заказчик несет ответственность за правильность формулировки своего заказа и за качество получаемых работ, связанное с неоднозначным трактованием такой формулировки.
        p 5.3.5 Заказчик несет ответственность за доступность своих Интернет-сайтов, на которых осуществляется размещение информационных материалов по созданным заказам. В случае недоступности Интернет-сайта Заказчика, Заказчик обязуется оплатить выполненную работу, если такая работа, в остальном, полностью соответствует условиям заказа.
        p 5.3.6 Заказчик несет ответственность за нарушение локальных и международных норм Авторского права. 
        h4 6. Блокировка аккаунта
        p 6.1 При нарушении ПС Администрация оставляет за собой право прекратить действие вашего логина и удалить любой контент.
        p 6.2 В случае возникновения подозрений на взлом аккаунта Пользователя Администрация вправе блокировать аккаунт для участия в форуме и на использование сервисов World of job (размещение заказов, выполнение работ, продажу статей) до выяснения обстоятельств и восстановления Пользователем защищенного доступа к аккаунту. Для восстановления доступа к аккаунту Пользователю необходимо обратиться к Администрации.
        p 6.3 В случае обнаружения попыток неоднократной регистрации Администрация сохраняет за собой право приостановить либо отменить регистрацию и отказать в использовании сервисов World of job (либо их частей).
        p 6.4 Средства заблокированного Автора, полученные им в результате деятельности на которую поступила жалоба, возвращаются Пользователю подавшему жалобу.
        p 6.5 Администрация оставляет за собой право изменить порядок действий при блокировке Автора.
        p 6.6 При блокировке аккаунта Заказчика на использование сервисов World of job (размещение заказов, выполнение работ, продажу статей) вывод средств Заказчика приостанавливается Администрацией на месяц. Администрация оставляет за собой право изменить сроки вывода средств заблокированных Заказчиков. 
        p 6.7 При блокировке аккаунта Заказчика на использование сервисов World of job (размещение заказов, выполнение работ, продажу статей) все заказы Заказчика останавливаются, статьи Заказчика снимаются с продажи. Администрация не предоставляет таким Заказчикам тексты  снятых с продажи статей, а также не предоставляет доступ к аккаунту, для изъятия снятых с продажи статей. Администрация оставляет за собой право изменить порядок действий при блокировке Заказчика.
        p 6.8 Администрация оставляет за собой право прекратить действие аккаунта любого Пользователя без объяснения причин. Возврат средств находящихся на балансе такого Пользователя, на кошелек платежной системы указанный Пользователем в своем Аккаунте, осуществляется по заявке Пользователя.
        h4 7. Техническая поддержка и связь с администрацией. Порядок разрешения споров.
        p 7.1 Любые возникающие в процессе использования Пользователем сервисов World of job вопросы, предложения, пожелания, жалобы, претензии, иная корреспонденцию, должны направляться Пользователем только в Администрацию.
        p 7.2 Обращения в Администрацию должны быть составлены в соответствии с правилами русского языка, в вежливой форме, без ругательств, нецензурных слов и выражений, оскорблений и угроз.
        p 7.3 При написании жалобы в Администрацию на нарушение Пользователем ПС необходимо руководствоваться Правилами обращения в ЛПА - указать Ник Пользователя, подробно изложить суть жалобы, указать URL (заказа, работы, статьи, сообщения в форуме) для подтверждения обоснованности жалобы.
        p 7.4 При написании жалобы в Администрацию об ошибках в работе World of job необходимо подробно описать ошибку и приложить принтскрин страницы с ошибкой. 
        p 7.5 При направлении в Администрацию своего сообщения не допускается дублирование данного сообщения в другой теме форума, в том числе в Личной связи с Администрацией. Любые комментарии и ответы Пользователя, касающиеся такого сообщения, необходимо размещать в той ветке форума, где было размещено само сообщение.
        p 7.6 Администрация обязуется рассмотреть поступившее сообщение и дать ответ Пользователю в течении 5 (Пяти) рабочих дней с момента поступления.
        p 7.6.1 Информация передаваемая посредством ЛПА в любой возможной форме (письменная, электронная, в иной форме, в том числе с использованием технических средств) Пользователем администрации и администрацией Пользователю является Конфиденциальной.
        p 7.6.2 Пользователи и администрация World of job обязуются не разглашать Конфиденциальную информацию третьим лицам полностью или частично, без предварительного письменного согласия. 
        p 7.7 Администрация оставляет за собой право игнорировать сообщения, направленные в ее адрес, которые составлены неккоректно.
        p 7.8 Администрация оставляет за собой право игнорировать вопросы, направленные в ее адрес, если на такой вопрос уже есть ответ в разделе помощи World of job.
        p 7.9 В случае систематического направления необоснованных жалоб (более трех) Пользователя на других Пользователей Администрация вправе заблокировать аккаунт такого Пользователя.
        p 7.10 Не рассматриваются анонимные претензии или претензии, не позволяющие идентифицировать пользователя на основе предоставленных им при регистрации данных.
        h4 8. Форс-мажорные обстоятельства
        p 8.1 Администрация не несет ответственности за неисполнение или частичное исполнение своих обязательств, если причиной данного неисполнения или частичного исполнения явились обстоятельства непреодолимой силы, далее Форс-мажорные обстоятельства.
        p 8.2 Под форс-мажорными обстоятельствами понимаются обстоятельства возникшие в связи с наступлением неотвратимых и непредвиденных сторонами событий чрезвычайного характера (пожар, наводнения, землетрясение, наличие или отсутствие законодательных норм, войны, террористические акты и т.п.), непосредственно влияющих на выполнение Администрацией своих обязательств.
        p 8.3 В случае наступления форс-мажорных обстоятельств сроки исполнения Администрацией своих обязательств соразмерно отодвигаются на время действия возникших обстоятельств и ликвидации их последствий.
        h4 9. Правила размещения и исполнения Заданий
        p 9.1	Запрещается размещение Заданий, целью или предметом которых является:
        p 1	предоставление и получение займов, пополнение счетов в платёжных системах, осуществление денежных переводов, приём платежей в пользу платёжных систем: AlertPay (alertpay.com), c-gold (c-gold.com), e-bullion (e-bullion.com), e-gold (e-gold.com), Liberty Reserve (libertyreserve.com), Pecunix (pecunix.com), PerfectMoney (perfectmoney.com), BitCoin (включая биржи, осуществляющие обмен);
        p 2	привлечение Пользователей на сторонние ресурсы, сайты, либо регистрация Пользователей на таких ресурсах, сайтах;
        p 3	реклама своих услуг и товаров или услуг и товаров, принадлежащих третьим лицам;
        p 4	фабрикация отзывов;
        p 5	услуги эротического характера;
        p 6	накрутка или изменение статистики сайтов, числа подписчиков в социальных сетях и т. д.;
        p 7	заказ автоматической или ручной рассылки приглашений и сообщений пользователям социальных сетей, email-рассылок;
        p 8	оказание услуг по распространению товаров Заказчика;
        p 9	лекарства, пищевые добавки, биологически активные добавки;
        p 10	яды;
        p 11	лекарственное сырье, получаемое от северного оленеводства (панты и эндокринное сырье);
        p 12	наркотические средства, психотропные вещества и их прекурсоры, культивирование наркосодержащих растений;
        p 13	услуги хранения, перевозки, изготовления, переработки наркотических средств, прекурсоров, психотропных веществ или их аналогов, а также незаконные приобретение, хранение, перевозка растений, содержащих наркотические средства или психотропные вещества, либо их частей, содержащих наркотические средства или психотропные вещества;
        p 14	услуги по организации занятий проституцией, вовлечение в занятие проституцией;
        p 15	контент для взрослых, любая порнография, секс-видеочаты, сайты с указанием на размещение откровенных фотографий;
        p 16	табачные изделия;
        p 17	алкогольная продукция, этиловый спирт;
        p 18	драгоценные, редкие металлы и камни, ювелирные изделия, в т. ч. отходы, содержащие драгоценные и редкоземельные металлы и драгоценные камни;
        p 19	золотые слитки, инвестиции в золотые слитки, любой оборот драгоценных металлов, природных драгоценных камней или жемчуга, в том числе продажа изделий из драгоценных металлов;
        p 20	услуги по оказанию содействия в ведении боевых действий, вне зависимости от занимаемой стороны конфликта, формы поддержки (гуманитарная помощь, поставка вооружения, средств связи, медикаментов и проч.);
        p 21	оружие и боеприпасы (гражданское, служебное, огнестрельное):
        p 1.	разработка, производство, испытание, хранение, ремонт и утилизация.
        p 2.	торговля, в том числе основными частями огнестрельного оружия.
        p 3.	боеприпасы, в том числе патроны и их составные части типа гильз и капсюлей.
        p 22	военная техника, запасные части, комплектующие изделия и приборы к ней, взрывчатые вещества, средства взрывания, пороха, все виды ракетного топлива, а также специальные материалы и специальное оборудование для их производства, специальное снаряжение личного состава военизированных организаций и нормативно — техническая продукция на их производство и эксплуатацию;
        p 23	боевые отравляющие вещества, средства защиты от них и нормативно — техническая документация на их производство и использование;
        p 24	услуги по изготовлению/переделке/ремонту огнестрельного оружия, его основных частей, боеприпасов, взрывчатых веществ или взрывных устройств;
        p 25	пестициды и агрохимикаты, ограниченные в обороте;
        p 26	сильнодействующие или ядовитые вещества;
        p 27	музейные предметы и музейные коллекции, включённые в состав Музейного фонда Российской Федерации (Музейный фонд — совокупность постоянно находящихся на территории Российской Федерации музейных предметов и музейных коллекций, гражданский оборот которых допускается только с соблюдением ограничений, установленных Федеральным законом от 26.05.1996 N 54-ФЗ «О Музейном фонде Российской Федерации и музеях в Российской Федерации»);
        p 28	рентгеновское оборудование, приборы и оборудование с использованием радиоактивных веществ и изотопов;
        p 29	космические аппараты и объекты наземной космической инфраструктуры, относящиеся к спутниковым навигационным системам и создаваемые за счёт средств федерального бюджета (ст. 5 Федерального закона Российской Федерации от 14 февраля 2009 г. N 22-ФЗ «О навигационной деятельности»);
        p 30	ракетно-космические комплексы, системы связи и управления военного назначения и нормативно — техническая документация на их производство и эксплуатацию;
        p 31	азартные игры, включая лотереи, букмекерские конторы и тотализаторы;
        p 32	форекс, бинарные аукционы, биткоины;
        p 33	финансовые пирамиды, HYIP;
        p 34	услуги по размещению сведений, материалов, порочащих честь и достоинство человека, нарушение тайны переписки, личной жизни;
        p 35	услуги по созданию, использованию и распространению вредоносных компьютерных программ;
        p 36	услуги кардшаринга;
        p 37	услуги по предоставлению неправомерного доступа к компьютерной информации;
        p 38	услуги по взлому аккаунтов третьих лиц, извлечению закрытой информации, её модификация;
        p 39	специальные технические средства, предназначенные для негласного получения информации;
        p 40	услуги по распространению и продаже сведений, составляющих коммерческую, налоговую или банковскую тайну;
        p 41	торговля/изготовление поддельных кредитных либо расчётных карт и иных платёжных инструментов;
        p 42	услуги по размещению публичных призывов к осуществлению экстремистской деятельности, возбуждению ненависти либо вражды, а равно унижение человеческого достоинства;
        p 43	услуги по организации экстремистского сообщества;
        p 44	услуги посредничества во взяточничестве;
        p 45	любые иные товары или услуги, запрещенные согласно законодательству Российской Федерации.

        h4 10. Финансовые операции на Сайте
        p 10.1 Расчеты между Пользователями выполняются с использованием программно-технических средствами Платежного Сервиса, привлекаемого Сайтом в качестве посредника в целях обеспечения необходимых условий для перевода средств со счета Заказчика на счет Исполнителя. Для проведения расчетов Пользователь:
        p - Руководствуясь инструкциями, указанными на экранных формах Платежного Сервиса, самостоятельно заполняет формы, предназначенные для перевода средств.
        p - Подтверждает выбранные параметры перевода.
        p 10.2 Расчеты между Пользователями выполняются в форме постоплаты. Для обеспечения соблюдения оговоренных в Задании условий, назначенная Заказчиком стоимость Задания резервируется на счете Заказчика с использованием программно-технических средств Платежного Сервиса. Перевод средств на счет Исполнителя возможен только при соблюдении условий, обозначенных в описании Задания. Итоговая сумма перевода, являющаяся оплатой услуг, оказанных Исполнителем по Заданию, может отличаться от стоимости, назначенной Заказчиком для данного Задания.
        p 10.3 За предоставление услуг по проведению расчетов Платежным Сервисом с Пользователя может взиматься вознаграждение в виде комиссии от суммы совершаемого перевода, которое Пользователь оплачивает за свой счет. При этом:
        p - Информация о размере комиссии размещается на экранных формах Платежного Сервиса при оформлении перевода.
        p - Размер комиссии указывается в экранной форме Платежного Сервиса до момента подтверждения перевода Пользователем. При этом до подтверждения перевода Пользователь вправе отказаться от оформления перевода, если по каким-либо причинам его не устроит размер взимаемой комиссии.
        p 10.4 Операторы по переводу денежных средств, привлекаемые в качестве Платежного Сервиса, определяются Администрацией и могут меняться по решению Администрации.
        p 10.5 Оплата Пользователем Услуг Сайта по настоящему Соглашению осуществляется путем безналичного перевода денежных средств в виде 100%-ной предоплаты или постоплаты, в зависимости от вида Услуги, с использованием программно-технических средств Платежного Сервиса.
        p 10.6 Администрация не несет ответственности за изменение состояния счета Пользователя в результате несанкционированного доступа третьих лиц к Регистрационным данным Пользователя по вине Пользователя.

</template>

<script>
export default {
  name: "agreement"
};
</script>
