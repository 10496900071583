<template lang="pug">
    #page
      h2 Реквизиты ООО «РСК»
      p Юр. адрес: 142717, обл. Московская, Ленинский р-н, п. Развилка, д. 9а, кв 54
      p ОГРН 1215000017033
      p ИНН 5003143430
      p КПП 500301001
      p Среднерусский Банк Сбербанка России
      p БИК: 044525225
      p К/С 30101810400000000225
      p Р/С 40702810340000101530
      p ОКПО 47281408
      p ОКАТО 46407000191
      p Генеральный директор: Красавин Михаил Викторович
      p Тел: +7 (916) 118-03-02
      p E-mail: rsk@mvk-group.r
</template>

<script>
export default {
  name: "requisites"
};
</script>
