<template lang="pug">
.profile-main
    ._user
        ._user-group
            ._user-left
              ._user-avatar
                  app-user-avatar
              ._edit-link(v-if="myProfile" @click="showSettings = !showSettings")  {{ editProfileText }}
            ._user-info
                ._user-name {{ user.name }} {{ user.last_name }}
                ._user-type {{ user.type | userTypes }}
                ._user-city(v-if="user.city") {{ user.city }}
        ._user-group.-column
            template(v-if="user.type === 'freelancer'")
                app-button(@click.native="sendMessage")._btn Написать сообщение
                app-button(@click.native="showPhone" type="without-bg" v-if="!phone")._btn Показать номер телефона
                a(href=`tel:${phone}` v-else)._user-phone {{ phone }}
            customer-stats(v-if="user.type === 'customer'")
    ._content
      ._content-main
        app-info(v-if="info" type="success") {{ info }}
        profile-settings(v-if="showSettings" @saved="changeUserInfo")
      aside._content-aside Aside       
</template>

<script>
import customerStats from "@/components/profile/customer-stats/customer-stats";
import usersModel from "@/models/users.model";
import profileSettings from "@/components/profile/profile-settings/profile-settings";
const USERS = new usersModel();

export default {
  name: "profile-main",
  components: { customerStats, profileSettings },
  data() {
    return {
      user: {
        name: "",
        last_name: "",
        type: "",
        city: "",
        id: 0
      },
      phone: null,
      showSettings: false,
      info: ""
    };
  },
  computed: {
    userId: state => state.$route.params.id,
    myUserId: state => state.$store.getters["user/id"],
    myProfile: state => state.userId == state.myUserId,
    editProfileText: state =>
      !state.showSettings ? "Редактировать профиль" : "Отменить редактирование"
  },
  async created() {
    await this.getUserInfo();
  },
  methods: {
    async getUserInfo() {
      await USERS.getOneById(this.userId).then(r => {
        this.user = r.data.user;
      });
    },
    async changeUserInfo() {
      await this.getUserInfo();
      this.info = "Ваши данные успешно сохранены!";
      this.showSettings = false;
    }
  },
  watch: {
    showSettings() {
      this.info = "";
    }
  }
};
</script>

<style src="./profile-main.scss" lang="scss" scoped></style>
