const userTypes = function(type) {
  if (!type) return "";

  const types = {
    admin: "Администратор",
    customer: "Заказчик",
    freelancer: "Фрилансер"
  };

  return types[type];
};

export default userTypes;
