<template lang="pug">
  section.support.section
    ._wrap.wrap

      navigation(title="Обращения в тех поддержку", :links="links")

      ._container
        ._list
            ._item(v-for="tiket in tikets" :key="tiket._id" v-if="tiket.type === type || type === 'all'")
              ._header
                ._left
                  span._request {{ tiket.number }}
                  span._status.-open(v-if="tiket.status") ОТКРЫТА
                  span._status.-close(v-else) ЗАКРЫТА

                span._date {{ tiket.datetime }}
              ._body
                router-link(:to="{ name: 'Tiket', params: { id: tiket._id } }")._link {{ tiket.title }}
                icon(name="arrow-right" component="support")._ico
</template>
<script>
import navigation from "@/components/app-common/app-v-navigation/app-v-navigation";
export default {
  name: "support",
  components: {
    navigation
  },
  data() {
    return {
      tikets: [
        {
          _id: 1,
          title:
            "При открытии страницы всех заказов и попытке отфильтровать заказы возникает проблема",
          number: "Заяка №17612",
          date: "10 мая 10:33",
          status: true,
          type: "open"
        },
        {
          _id: 2,
          title:
            "При открытии страницы всех заказов и попытке отфильтровать заказы возникает проблема",
          number: "Заяка №17612",
          date: "10 мая 10:33",
          status: true,
          type: "open"
        },
        {
          _id: 3,
          title:
            "При открытии страницы всех заказов и попытке отфильтровать заказы возникает проблема",
          number: "Заяка №17612",
          date: "10 мая 10:33",
          status: false,
          type: "close"
        },
        {
          _id: 4,
          title:
            "При открытии страницы всех заказов и попытке отфильтровать заказы возникает проблема",
          number: "Заяка №17612",
          date: "10 мая 10:33",
          status: true,
          type: "open"
        }
      ],
      links: [
        {
          type: "all",
          name: "Support",
          title: "Все обращения"
        },
        {
          type: "open",
          name: "Support",
          title: "Открытые"
        },
        {
          type: "close",
          name: "Support",
          title: "Закрытые"
        }
      ]
    };
  },
  computed: {
    type: state => state.$route.params.type
  }
};
</script>
<style lang="scss">
@import "./support.scss";
</style>
