<template lang="pug">
  .toast(:class="type" @click="$emit('remove')")
    ._close-btn
      icon(name="cross" component="toast")._cross-icon
    ._text {{ message }}
    ._progress
      ._progress-bar(:style="'width:'+percentageElapsed+'%'")
</template>

<script>
import ToastMixin from "vue-my-toasts/src/mixins/ToastMixin";
import TimerMixin from "vue-my-toasts/src/mixins/TimerMixin";

export default {
  name: "toast",

  mixins: [ToastMixin, TimerMixin],

  props: {
    badge: {
      type: [String, Boolean],
      required: false,
      default: false
    }
  },

  computed: {
    color() {
      switch (this.type) {
        case "base":
          return "blue";
        case "warning":
          return "orange";
        case "error":
          return "red";
        case "success":
          return "green";
        default:
          return "blue";
      }
    }
  }
};
</script>

<style lang="scss">
@import "toast";
</style>
