<template lang="pug">
  .page-container
    projects
</template>

<script>
import projects from "../components/projects/projects/projects.vue";
export default {
  name: "Projects",
  components: {
    projects
  }
};
</script>
