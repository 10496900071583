import guest from "./middleware/guest";
import customer from "./middleware/customer";

import Home from "../pages/Home";
import Auth from "../pages/Auth";
import CreateOrder from "../pages/Create";
import About from "../pages/About";
import Customer from "../pages/Customer";
import Freelancer from "../pages/Freelancer";
import News from "../pages/News";
import Projects from "../pages/Projects";
import Project from "../pages/Project";
import Freelancers from "../pages/Freelancers";
import Profile from "../pages/Profile";
import Policy from "../pages/Policy";
import Post from "../pages/Post";
import Support from "../pages/Support";
import Tiket from "../pages/Tiket";
import Static from "../pages/Static";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/auth/:type?/:user?",
    name: "Auth",
    component: Auth,
    meta: {
      middleware: [guest]
    }
  },
  {
    path: "/create-order",
    name: "Create",
    component: CreateOrder,
    meta: {
      middleware: [customer]
    }
  },
  {
    path: "/about",
    name: "About",
    component: About
  },
  {
    path: "/for-customer",
    name: "Customer",
    component: Customer
  },
  {
    path: "/for-freelancer",
    name: "Freelancer",
    component: Freelancer
  },
  {
    path: "/news/:type?",
    name: "News",
    component: News
  },
  {
    path: "/news/post/:id",
    name: "Post",
    component: Post
  },
  {
    path: "/projects/",
    name: "Projects",
    component: Projects
  },
  {
    path: "/freelancers",
    name: "Freelancers",
    component: Freelancers
  },
  {
    path: "/project/:id",
    name: "Project",
    component: Project
  },
  {
    path: "/policy",
    name: "Policy",
    component: Policy
  },
  {
    path: "/support/:type?",
    name: "Support",
    component: Support
  },
  {
    path: "/support/tiket/:id",
    name: "Tiket",
    component: Tiket
  },
  {
    path: "/user/:id/:type?",
    name: "Profile",
    component: Profile
  },
  {
    path: "/static/:page",
    name: "Static",
    component: Static
  },
  {
    path: "/404",
    name: "404"
  }
];

export default routes;
