<template lang="pug">
  .page-container
    support
</template>
<script>
import support from "@/components/support/tiket/tiket.vue";
export default {
  name: "Support",
  components: {
    support
  }
};
</script>
