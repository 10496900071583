<template lang="pug">
  .page-container
    posts
</template>

<script>
import posts from "@/components/news/posts/posts.vue";
export default {
  name: "News",
  components: { posts }
};
</script>
