<template lang="pug">
section.auth.section
  ._wrap.wrap
    h1._title.title {{ title }}
    components(:is="`${type}Form`")._form
</template>

<script>
import loginForm from "./auth-forms/login-form";
import registerForm from "./auth-forms/register-form";
import recoveryForm from "./auth-forms/forgot-form";

export default {
  name: "auth",
  components: { loginForm, registerForm, recoveryForm },
  computed: {
    type: state =>
      state.$route.params.type ? state.$route.params.type : "login",
    title: state =>
      state.type === "login"
        ? "Авторизация"
        : state.type === "register"
        ? "Регистрация"
        : "Восстановление пароля",
    authStatus: state => state.$store.getters["auth/status"]
  },
  methods: {
    awayOut() {
      if (this.authStatus) {
        this.$router.push({ name: "Home" });
      }
    }
  },
  created() {
    this.awayOut();
  }
};
</script>

<style src="./auth.scss" lang="scss" scoped></style>
