<template lang="pug">
    button.app-button(:class="modificator" :disabled="disabled")
        icon(:name="icon" v-if="icon")._icon
        slot
</template>

<script>
export default {
  name: "app-button",
  props: {
    type: {
      required: false,
      type: String,
      default: "blue"
    },
    size: {
      required: false,
      type: String,
      default: "xs"
    },
    icon: {
      required: false,
      type: String,
      default: ""
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    typeModificator: state => `app-button_${state.type}`,
    sizeModificator: state => (state.size ? `app-button_${state.size}` : ""),
    modificator: state => `${state.typeModificator} ${state.sizeModificator}`
  }
};
</script>

<style src="./app-button.scss" lang="scss" scoped></style>
