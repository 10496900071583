<template lang="pug">
    .app-info(:class="type")
        ._icon-box(v-if="icon")
        ._content
            slot
</template>

<script>
export default {
  name: "app-info",
  props: {
    icon: {
      type: String,
      default: "",
      required: false
    },
    type: {
      type: String,
      default: "info",
      required: false
    }
  }
};
</script>

<style src="./app-info.scss" lang="scss" scoped></style>
