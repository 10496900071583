<template lang="pug">
    #page
        h2 Политика конфиденциальности
        p Политика конфиденциальности персональных данных (далее — Политика) действует в отношении всей информации, которую Администрация Сайта может получить о Пользователе во время использования им Сайта, а также в ходе исполнения Администрацией Сайта любых соглашений и договоров с Пользователем. Все положения настоящей Политики, применимые к Сайту, имеют равную силу в отношении Мобильного приложения.
        h4 1. Основные понятия
        p 1.1 Сайт – сайт, расположенный в сети Интернет по адресу https://worldofjob.ru.
        p 1.2 Пользователь – лицо, зарегистрировавшееся на сайте в установленном порядке или указавшее свою персональную информацию посредством Формы обратной связи на сайте с последующей целью передачи данных Администрации Сайта.
        p 1.3 Форма обратной связи – форма, где Пользователь размещает свою персональную информацию с целью передачи данных Администрации Сайта.

        h4 2. Общие положения
        p 2.1 Настоящая Политика конфиденциальности является официальным типовым документом Администрации Сайта и устанавливает обязательства Администрации сайта по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет при использовании Сайта.
        p 2.2 Целью настоящей Политики конфиденциальности является обеспечение надлежащей защиты информации о Пользователе, в т.ч. его персональных данных от несанкционированного доступа и разглашения.
        p 2.3 Отношения, связанные со сбором, хранением, распространением и защитой информации о пользователях регулируются настоящей Политикой конфиденциальности и действующим законодательством Российской Федерации.
        p 2.4 Действующая редакция Политики конфиденциальности, является публичным документом, разработана Администрацией Сайта и доступна любому пользователю сети Интернет при переходе по гипертекстовой ссылке «Политика конфиденциальности».
        p 2.5 Настоящая Политика неукоснительно выполняется всеми сотрудниками Сайта.
        p 2.6. Использование Сайта, а также его отдельных функций означает безоговорочное согласие Пользователя с настоящей Политикой и указанными в ней условиями обработки его персональных данных; в случае несогласия с этими условиями Пользователь должен воздержаться от использования Сайта.
        p 2.7 Настоящая Политика применима только к информации, обрабатываемой в ходе использования Сайта. Администрация Сайта не контролирует и не несет ответственность за обработку информации сайтами третьих лиц, на которые Пользователь может перейти по ссылкам, доступным внутри Сайта.
        p 2.8 Администрация Сайта не проверяет достоверность персональных данных, предоставляемых Пользователем, и не имеет возможности оценивать его дееспособность. Однако Администрация Сайта исходит из того, что пользователь предоставляет достоверные и достаточные персональные данные и поддерживает их в актуальном состоянии.

        h4 3. Условия и цели сбора и обработки персональных данных Пользователя
        p 3.1 Администрация Сайта собирает и хранит только те персональные данные, которые необходимы для предоставления полного или частичного функционала Сайта или исполнения соглашений и договоров с Пользователем, за исключением случаев, когда законодательством предусмотрено обязательное хранение персональных данных в течение определенного законом срока.
        p 3.2 Администрация Сайта может собирать и обрабатывать с согласия Пользователя такие данные как: имя, фамилия, профильная фотография, дата рождения, город и страна, e-mail, телефон, электронные кошельки, ip-адрес, cookies.
        p 3.3 Администрация Сайта обрабатывает персональные данные Пользователя в целях предоставления доступа к функционалу сайта и выполнения условий Пользовательского соглашения.
        p 3.4 Обработка персональных данных осуществляется на основе принципов:
        p 3.4.1 законности целей и способов обработки персональных данных и добросовестности;
        p 3.4.2 соответствия целей обработки персональных данных целям, заранее определенным и заявленным при сборе персональных данных;
        p 3.4.3 соответствия объёма и характера обрабатываемых персональных данных способам обработки персональных данных и целям обработки персональных данных;
        p 3.4.4 недопущения обработки Персональных данных, несовместимой с целями сбора Персональных данных;
        p 3.4.5 недопустимости объединения созданных баз данных, содержащих Персональные данные, обработка которых осуществляется в целях, несовместимых между собой.
        p 3.5 В случаях, установленных законодательством Российской Федерации, Администрация Сайта вправе осуществить передачу персональных данных Пользователей.

        h4 4. Условия обработки персональных данных Пользователя
        p 4.1 Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.
        p 4.2 В отношении персональных данных Пользователя сохраняется их конфиденциальность, кроме случаев добровольного предоставления Пользователем информации о себе для общего доступа неограниченному кругу лиц. При использовании отдельных функций Сайта, Пользователь соглашается с тем, что определённая часть его персональной информации становится общедоступной.
        p 4.3 При обработке персональных данных Пользователей Администрация Сайта руководствуется:
        p 4.3.1 Федеральным законом от 27.07.2006 г. № 152-ФЗ «О персональных данных»;
        p 4.3.2 Постановлением Правительства Российской Федерации от 01.11.2012 г. № 1119 «Об утверждении требований к защите персональных данных при их обработке в информационных системах персональных данных»;
        p 4.3.3 Постановлением Правительства Российской Федерации от 15.09.2008 г. № 687 «Об утверждении Положения об особенностях обработки персональных данных, осуществляемой без использования средств автоматизации»;
        p 4.3.4 Приказом ФСТЭК России от 18 февраля 2013 г. № 21 «Об утверждении Состава и содержания организационных и технических мер по обеспечению безопасности персональных данных при их обработке в информационных системах персональных данных»;
        p 4.3.5 Приказом ФСБ России от 10.07.2014 № 378 «Об утверждении Состава и содержания организационных и технических мер по обеспечению безопасности персональных данных при их обработке в информационных системах персональных данных с использованием средств криптографической защиты информации, необходимых для выполнения установленных Правительством Российской Федерации требований к защите персональных данных для каждого из уровней защищенности».
        p 4.4 Персональные данные Пользователя не передаются каким-либо третьим лицам, за исключением случаев, прямо предусмотренных настоящей Политикой конфиденциальности, указанных в п. 4.2.

        h4 5. Права субъекта Персональных данных. Изменение и удаление персональных данных.
        p 5.1 Пользователь имеет право в любой момент изменить (обновить, дополнить) предоставленные им персональные данные или их часть, воспользовавшись функцией редактирования персональных данных в персональном разделе Сайта, либо направив соответствующий запрос по электронной почте, указанной на странице Сайта “Контакты”.
        p 5.2 Персональные данные Пользователя уничтожаются при желании самого пользователя на основании его обращения, либо по инициативе Администрации Сайта, если это не противоречит действующему законодательству Российской Федерации, без объяснения причин путём удаления Администрацией Сайта информации, размещённой Пользователем.
        p 5.3 Запрос об изменении (обновлении, дополнении, удалении) персональных данных, направляемый Пользователем, должен содержать следующую информацию:
        p - адрес электронной почты, который был использован Пользователем при регистрации на Сайте;
        p - дату регистрации пользователя на сайте;
        p - текст запроса в свободной форме
        p 5.4 Администрация Сайта обязуется рассмотреть и направить ответ на поступивший запрос Пользователя в течение 14 календарных дней с момента поступления обращения.
        p 5.5 Вся корреспонденция, полученная Администрацией Сайта от Пользователя, относится к информации ограниченного доступа и без письменного согласия Пользователя разглашению не подлежит. Персональные данные и иная информация о Пользователе, направившем запрос, не могут быть без специального согласия Пользователя использованы иначе, как для ответа по теме полученного запроса или в случаях, прямо предусмотренных законодательством.

        h4 6. Обработка персональных данных при помощи файлов cookie
        p 6.1 Файлы cookie, передаваемые Администрации Cайта техническим устройством Пользователя, могут использоваться для предоставления Пользователю персонализированных функций Сайта, в статистических и исследовательских целях, а также для улучшения Сайта.
        p 6.2 Cайт использует файлы cookie для улучшения качества обслуживания. Файлы cookie используются для получения информации о том, как Пользователь приходит на Сайт, какие страницы посещает, а также о прочих действиях, которые Пользователь осуществляет на Сайте. Указанные данные необходимы для повышения удобства навигации Пользователя на Сайте.
        p 6.3 Данные Пользователя, полученные посредством использования файлов cookie, являются анонимными.

        h4 7. Меры, применяемые для защиты персональных данных Пользователя
        p 7.1 Администрация сайта принимает необходимые и достаточные организационные и технические меры для защиты персональных данных Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с ней третьих лиц.

        h4 8. Изменение Политики конфиденциальности
        p 8.1 Администрация Сайта вправе вносить изменения в настоящую Политику конфиденциальности. При внесении изменений в Политику конфиденциальности, Администрация Сайта уведомляет об этом Пользователя путём размещения новой редакции Политики конфиденциальности на Сайте worldofjob.ru.
</template>

<script>
export default {
  name: "confidential"
};
</script>
