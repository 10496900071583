<template lang="pug">
  .projects.section
    ._wrap.wrap
          projectsFilter(@changeFilter="filter" :resetFilter="resetFilter")
          ._list
            app-loading(v-if="loading")
            template(v-else-if="projects.length")
              project(v-for="project in projects" :key="project.id" :project="project")
              ._footer
                button(@click="showMore()" type="button" v-if="projects.length < count").btn.-blue Показать ещё
                ._no-more(v-else) Вы просмотрели все проекты
            template(v-else)
              ._no-more Не найдено ни одного проекта по вашему запросу
                button(@click="resetFilter = true")._reset-btn.btn.btn_blue Сбросить фильтр
</template>
<script>
import projectsFilter from "@/components/projects/projects-filter/projects-filter.vue";
import project from "@/components/projects/project/project";
import ProjectsModel from "@/models/projects.model";

const PROJECTS = new ProjectsModel();

export default {
  name: "projects",
  components: {
    projectsFilter,
    project
  },
  data() {
    return {
      loading: true,
      projects: [],
      page: 1,
      projectsPerPage: 10,
      category: 0,
      priceFrom: 0,
      priceTo: 1000000,
      count: 0,
      isShowMoreClicked: false,
      resetFilter: false
    };
  },
  computed: {
    filterData: state => ({
      category: state.category,
      priceFrom: state.priceFrom,
      priceTo: state.priceTo,
      page: state.page,
      projectsPerPage: state.projectsPerPage
    })
  },
  methods: {
    async getProjects() {
      await PROJECTS.getFiltered(this.filterData).then(r => {
        const projects = r.data.list;
        if (projects.length) {
          projects.map(project => this.projects.push(project));
        }
        setTimeout(() => {
          this.loading = false;
        }, 200);
      });
    },
    async getProjectsCount() {
      await PROJECTS.countFiltered(this.filterData)
        .then(r => {
          this.count = r.data.count;
        })
        .catch(e => {
          console.log(e);
        });
    },
    showMore() {
      this.isShowMoreClicked = true;
      this.page++;
    },
    filter(data) {
      this.category = data.category;
      this.priceFrom = data.priceFrom;
      this.priceTo = data.priceTo;
      this.page = 1;
    }
  },
  watch: {
    async filterData() {
      if (!this.isShowMoreClicked) {
        this.loading = true;
        this.resetFilter = false;
        this.projects = [];
        this.getProjects();
        this.getProjectsCount();
      } else {
        this.getProjects();
      }
      this.isShowMoreClicked = false;
    }
  }
};
</script>
<style lang="scss">
@import "projects";
</style>
