<template lang="pug">
  section.posts.section
    ._wrap.wrap
      navigation(title="Категории новостей", :links="links")
      ._container
        ._list
          router-link(v-for="post in posts" :key="post._id" :to="{ name: 'Post', params: { id: post._id } }" )._item
            img(:src="require(`./img/${post.preview}`)", :alt="post.title")._preview
            ._group
              ._subtitle {{ post.title }}
              p._description {{ post.descr }}
              span._tag {{ post.tag }}
        ._footer
          button.btn.-blue-darken._btn Загрузить еще
</template>
<script>
import navigation from "@/components/app-common/app-v-navigation/app-v-navigation";
export default {
  name: "posts",
  components: {
    navigation
  },
  data() {
    return {
      links: [
        {
          type: "all",
          name: "News",
          title: "Все новости"
        },
        {
          type: "freelancers",
          name: "News",
          title: "Советы исполнителям"
        },
        {
          type: "customers",
          name: "News",
          title: "Советы заказчикам"
        },
        {
          type: "service",
          name: "News",
          title: "Новости сервиса"
        }
      ],
      posts: [
        {
          _id: 1,
          title: "Как получать больше заказов?",
          preview: "post-preview.jpg",
          tag: "Советы исполнителям",
          descr:
            "Собрали советы о том, как лучше общаться с заказчиками, чтобы получать больше заказов. Многие советы уже избиты, но даже их не применяет 99% фрилансеров"
        },
        {
          _id: 2,
          title: "Как получать больше заказов?",
          preview: "post-preview-2.jpg",
          tag: "Советы исполнителям",
          descr:
            "Собрали советы о том, как лучше общаться с заказчиками, чтобы получать больше заказов. Многие советы уже избиты, но даже их не применяет 99% фрилансеров"
        }
      ]
    };
  }
};
</script>
<style lang="scss">
@import "./posts.scss";
</style>
