import Vue from "vue";
import moment from "moment";

export default Vue.mixin({
  data() {
    return {
      chatDialogId: 0,
      pageWidth: window.innerWidth
    };
  },
  created() {
    this.getPageWidth();
    window.addEventListener("resize", this.getPageWidth);
  },
  destroy() {
    window.removeEventListener("resize", this.getPageWidth);
  },
  computed: {
    myUser: state => state.$store.getters["user/info"],
    isAuth: state => state.myUser.id,
    isMyProfile: state => state.$route.params.id === state.myUser.id,
    isGuest: state => state.myUser.group === "guest",
    isFreelancer: state => state.myUser.group === "freelancer",
    isCustomer: state => state.myUser.group === "customer"
  },
  methods: {
    getDate(date, format) {
      return moment(date).format(format);
    },
    getPageWidth() {
      this.pageWidth = window.innerWidth;
    }
  }
});
