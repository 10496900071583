<template lang="pug">
  section.register-steps.section
    ._wrap.wrap
      ._content
        h1.title._title {{ titles[type] }}
        ._list
          ._item(v-for="step in steps" :key="step.id")
            ._image
              icon(:name="step.icon" component="register-steps")._icon
            ._group
              ._title-sm.title-sm {{ step.title }}
              p._description {{ step.content }}
              router-link(:to="{name: 'Auth', params: { type: 'register', user: type }}" tag="button" v-if="step.btn").btn.-blue-darken._btn Зарегистрироваться
      page-navigation(:links="links")
</template>

<script>
import PageNavigation from "../page-navigation/page-navigation";
export default {
  name: "register-steps",
  components: { PageNavigation },
  props: {
    type: {
      require
    }
  },
  data() {
    return {
      titles: {
        customer: "Как начать работу заказчику?",
        freelancer: "Как начать работу исполнителю?"
      },
      types: {
        customer: [
          {
            id: 1,
            title: "Зарегистрируйтесь",
            icon: "user",
            content:
              "Регистрация не займёт много времени, но даст много полезного. Вы сможете создавать неограниченное количество задач и иметь доступ к более чем 1000 фрилансерам, которые готовы выполнить любую вашу задачу в короткие сроки и по приемлемой цене."
          },
          {
            id: 2,
            title: "Разместите задание",
            icon: "list",
            content:
              "Вы сами решаете сколько хотите заплатить и за какой срок вам нужно выполнить задачу. Вашу задачу увидят лучшие фрилансеры нашего сервиса и предложат свои решения для вашей задачи любой сложности."
          },
          {
            id: 3,
            title: "Получайте результат",
            icon: "testing",
            content:
              "Вы платите только тогда, когда довольны результатом выполненной работы. Для вас нет никаких рисков потерять свои деньги так как каждая сделка в нашем сервисе проводиться через безопасную форму и контролируются арбитражом.",
            btn: true
          }
        ],
        freelancer: [
          {
            id: 1,
            title: "Зарегистрируйтесь",
            icon: "user",
            content:
              "Регистрация не займёт много времени, но откроет доступ к тысячам проектов, которые ждут пока вы им поможете. Вы сможете сами выбирать какую задачу вы хотите взять в работу, а какая задача вам не интересна. Тысячи заказчиков уже ждут, когда ты поможешь им с решением их задач."
          },
          {
            id: 2,
            title: "Выберите задачу на свой вкус",
            icon: "list",
            content:
              "Выбирайте только те задачи, которые нравятся вам. В своих заявках к задачам вы должны заинтересовать заказчика, тогда ваши шансы быть выбранным в качестве исполнителя - значительно вырастут. Вы можете рассказать о подобных проектах или предложить оригинальный вариант решения задачи и почему вам кажется, что этот вариант будет лучше."
          },
          {
            id: 3,
            title: "Получайте деньги",
            icon: "time",
            content:
              "Соблюдайте сроки и выполняйте задачи качественно - так мы гарантируем, что вы получите свои деньги сразу после согласования принятия работы с заказчиком, а если заказчик отказывается платить - наш арбитраж сможем оперативно вам помочь.",
            btn: true
          }
        ]
      },
      linksData: {
        customer: [
          {
            id: 1,
            title: "Цены",
            to: ".task-price"
          },
          {
            id: 2,
            title: "Гарантия",
            to: ".protect"
          },
          {
            id: 3,
            title: "Отзывы",
            to: ".testimonials"
          },
          {
            id: 4,
            title: "Лучшие фрилансеры",
            to: ".best"
          }
        ],
        freelancer: [
          {
            id: 1,
            title: "Гарантия защиты",
            to: ".protect"
          },
          {
            id: 2,
            title: "FAQ",
            to: ".faq"
          }
        ]
      }
    };
  },
  computed: {
    steps: state => state.types[state.type],
    links: state => state.linksData[state.type]
  }
};
</script>

<style lang="scss">
@import "register-steps";
</style>
