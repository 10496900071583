<template lang="pug">
form.form(@submit.prevent="login")
      ._group(v-if="error")
        app-info(type="error") {{ error }}
      ._group
        label(for="email")._label E-mail
        input._input#email(type="email" v-model="email" :class="{'form__input_error': ($v.email.$error && $v.email.$dirty),  'form__input_valid': (!$v.email.$error && $v.email.$dirty)}")
        ._group-error(v-if="$v.email.$error && $v.email.$dirty") Введите корректный e-mail
      ._group
        ._group-header
          label(for="password")._label Пароль
          router-link(:to="{name: 'Auth', params: { type: 'recovery' }}" tag="a")._link Забыли пароль?
        input._input#password(type="password" v-model="password" placeholder="********" :class="{'form__input_error': ($v.password.$error && $v.password.$dirty),  'form__input_valid': (!$v.password.$error && $v.password.$dirty)}")

      button(type="submit").btn.-blue-darken._send Войти

      ._footer
        span._message Нет аккаунта?
        router-link(:to="{name: 'Auth', params: { type: 'register' }}" tag="a")._link Зарегистрироваться
</template>

<script>
import { email, required } from "vuelidate/lib/validators";
import UsersModel from "@/models/users.model";
import AuthModel from "@/models/auth.model";
import API from "@/API";
const USERS = new UsersModel();
const AUTH = new AuthModel();

export default {
  name: "login-form",
  data: () => ({
    api: API.instance,
    email: "freelancer@kprod.agency",
    password: "1234",
    error: ""
  }),
  validations: {
    email: {
      required,
      email
    },
    password: {
      required
    }
  },
  methods: {
    async login() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }

      await AUTH.login({
        email: this.email,
        password: this.password
      })
        .then(async r => {
          console.log(this.api);
          this.api.setTokens = r.data;
          await USERS.getMyUser().then(r => {
            const response = r.data;
            if (response.id) {
              this.$store.dispatch("user/UPDATE_DATA", response);
            }
            this.$router.push({ name: "Home" });
          });
        })
        .catch(e => {
          console.log(e);
          this.error = "Неверный логин или пароль";
          this.email = "";
          this.password = "";
        });
    }
  }
};
</script>

<style src="./form.scss" lang="scss" scoped></style>
