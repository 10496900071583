<template lang="pug">
  .chat
    ._header
      span._request {{ info.number }}
      span._status.-open(v-if="info.status") ОТКРЫТА
      span._status.-close(v-else) ЗАКРЫТА
    ._body
      ._dialog
        ._message(v-for="message in messages" :key="message._id")
          img(src="./img/tiket-user.jpg", :alt="message.name")._message-avatar
          ._message-group
            ._message-header
              ._message-name {{ message.name }}
              span._message-date {{ message.date }}
            ._message-body(v-html="message.text")
    form._form
      textarea(name="message" placeholder="Введите текст сообщения...")._form-control
      button(type="submit")._send
        icon(name="send-message" component="app-chat")._form-ico

</template>
<script>
export default {
  name: "app-chat",
  props: {
    info: {
      type: Object,
      required: true
    },
    messages: {
      type: Array,
      required: true
    }
  }
};
</script>
<style lang="scss">
@import "./app-chat.scss";
</style>
