<template lang="pug">
section.faq.section(v-if="questions.length")
  ._wrap.wrap
    h1.title._title Часто задаваемые вопросы
    ._list
      ._item(v-for="item in questions" :key="item.id" :class="{active: item.active}")
        ._header(@click="item.active = !item.active")
          ._item-title {{ item.question }}
          icon(name="chevron" component="faq")._icon
        ._body(v-html="item.answer")
    router-link(:to="{name: 'Auth', params: { type: 'register', user: 'freelancer'}}" tag="button" type="button").btn.-blue-darken._btn Начать зарабатывать
</template>

<script>
export default {
  name: "faq",
  props: {
    questions: {
      type: Array
    }
  }
};
</script>

<style lang="scss" scoped>
@import "faq";
</style>
