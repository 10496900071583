import Vue from "vue";
import Vuex from "vuex";
import { user } from "./modules/user";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    categories: [],
    isChatOpen: false,
    activeChatId: null
  },
  getters: {
    categories: state => state.categories,
    isChatOpen: state => state.isChatOpen,
    activeChatId: state => state.activeChatId
  },
  actions: {
    async GET_FAQ(commit, category) {
      return await window.$server.get(`/faq/${category}`);
    },
    async SAVE_CATEGORIES({ commit }, categories) {
      commit("SAVE_CATEGORIES", categories);
    },
    async CREATE_PROJECT(commit, projectData) {
      return await window.$server.post("/projects/", projectData);
    },
    async GET_PROJECT(commit, id) {
      return await window.$server.get(`project/${id}`);
    },
    SET_CHAT_STATUS({ commit }, status) {
      commit("SET_CHAT_STATUS", status);
    },
    SET_ACTIVE_CHAT_ID({ commit }, id) {
      commit("SET_ACTIVE_CHAT_ID", id);
    }
  },
  mutations: {
    SAVE_CATEGORIES(state, categories) {
      state.categories = categories;
    },
    SET_CHAT_STATUS(state, status) {
      state.isChatOpen = status;
    },
    SET_ACTIVE_CHAT_ID(state, id) {
      state.activeChatId = id;
    }
  },
  modules: { user }
});
