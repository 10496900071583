import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuelidate from "vuelidate";
import VueMyToasts from "vue-my-toasts";
import VueScrollTo from "vue-scrollto";
import VueTippy from "vue-tippy";
import "@/components/app-common/ui-components";

// Filters
import declinationOfNumber from "./filters/declination.filter";
import userTypes from "./filters/userTypes.filter";
import projectStatusFilter from "./filters/projectStatus.filter";

import "vue-my-toasts/dist/vue-my-toasts.css";
import toast from "./components/app-common/toast/toast";
import VueMask from "v-mask";
import "@/mixins/common.mixin";

Vue.use(VueMask);
Vue.use(VueTippy);
Vue.use(vuelidate);

Vue.use(VueMyToasts, {
  component: toast,
  options: {
    width: "300px",
    position: "bottom-right"
  }
});
Vue.use(VueScrollTo);

Vue.config.productionTip = false;

Vue.filter("userTypes", userTypes);
Vue.filter("declinationOfNumber", declinationOfNumber);
Vue.filter("projectStatusFilter", projectStatusFilter);

const vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

export default vm;
