<template lang="pug">
  form.filter
    ._header
      ._header-title Фильтр заказов
      icon(name="arrow-right" component="projects-filter")._angle
    ._body
      ._group
        ._title Цена:
        vue-slider(v-model="price" :min="priceMin" :max="priceMax" :step="priceStep" tooltip="always")._range-slider

      ._group(v-if="categories.length")
        ._title Категории: 
        ._cat-list
          ._cat-item(v-for="category in categories" v-if="category.parentId === 0" :key="category.id" :class="{'filter__cat-item_open': category.status}")
            span._cat-title(@click="category.status = !category.status") {{ category.name }}
              icon(name="arrow-right" component="projects-filter")._arrow

            ._cat-dropdown
              ._cat-subitem
                input(type="checkbox" v-model="category.active" :value="category.id" :id="`design-`+category.id")._input.-radio
                label(:for="`design-`+category.id")._label.-radio Вся категория
                  span._dot
              ._cat-subitem(v-for="subcategory in categories" v-if="subcategory.parentId === category.id")
                input(type="checkbox" v-model="subcategory.active" :value="subcategory.id" :id="`design-`+subcategory.id" :disabled="subcategory.parentId === category.id && category.active")._input.-radio
                label(:for="`design-`+subcategory.id")._label.-radio {{ subcategory.name }}
                  span._dot
</template>
<script>
import VueSlider from "vue-slider-component";

export default {
  name: "projects-filter",
  components: { VueSlider },
  data() {
    return {
      price: [100, 500000],
      priceMin: 100,
      priceMax: 500000,
      priceStep: 100,
      categories: [],
      filterTimeout: null
    };
  },
  props: {
    resetFilter: {
      required: true,
      type: Boolean,
      default: false
    }
  },
  computed: {
    priceFrom: state => state.price[0],
    priceTo: state => state.price[1],
    getterCategories: state => state.$store.getters.categories,
    activeCategory: state =>
      state.categories
        .filter(category => category.active)
        .map(category => category.id)
        .join(","),
    filter: state => ({
      category: state.activeCategory,
      priceFrom: state.priceFrom,
      priceTo: state.priceTo
    })
  },
  async mounted() {
    this.createCategories();
  },
  methods: {
    createCategories() {
      this.categories = this.getterCategories.map(category => ({
        ...category,
        active: false,
        status: false
      }));
    },
    checkNumbers(e) {
      const regExp = /\d/;
      if (!regExp.test(e.key) && e.key !== "Backspace" && e.key !== "Delete")
        return e.preventDefault();
    }
  },
  watch: {
    getterCategories() {
      this.createCategories();
    },
    filter() {
      clearTimeout(this.filterTimeout);
      this.filterTimeout = setTimeout(() => {
        this.$emit("changeFilter", this.filter);
      }, 400);
    },
    resetFilter(needReset) {
      if (needReset) {
        this.categories.map(category => (category.active = false));
        this.price = [this.priceMin, this.priceMax];
      }
    }
  }
};
</script>
<style lang="scss">
@import "./projects-filter";

$themeColor: $blue;
$tooltipFontSize: 12px;
@import "~vue-slider-component/lib/theme/default.scss";
</style>
