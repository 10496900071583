<template lang="pug">
  aside.page-navigation
    ._title Навигация
    ._list
      ._link(v-for="link in links" :key="link.id" @click="scrollTo(link.to)")
        icon(name="chevron-right" component="common")._icon
        | {{ link.title }}
</template>

<script>
export default {
  name: "page-navigation",
  props: {
    links: {
      require
    }
  },
  methods: {
    scrollTo(to) {
      this.$scrollTo(to);
    }
  }
};
</script>

<style lang="scss">
@import "page-navigation";
</style>
