export const user = {
  namespaced: true,
  state: {
    id: 0,
    name: "",
    group: "guest",
    referral_award: 0,
    balance: 0
  },
  getters: {
    id: state => state.id,
    group: state => state.group,
    info: state => ({
      id: state.id,
      name: state.name,
      group: state.group,
      referralAward: state.referral_award,
      balance: state.balance
    })
  },
  actions: {
    async UPDATE_DATA({ commit }, response) {
      commit("UPDATE_DATA", response);
    },
    RESET_DATA({ commit }) {
      commit("RESET_DATA");
    }
  },
  mutations: {
    UPDATE_DATA(state, data) {
      state.id = data.id;
      state.name = data.name;
      state.group = data.type;
      state.referral_award = data.referral_award;
      state.balance = data.balance;
    },
    RESET_DATA(state) {
      state.id = 0;
      state.name = "";
      state.group = "guest";
      state.referralAward = 0;
      state.balance = 0;
    }
  }
};
