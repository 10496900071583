<template lang="pug">
    header.app-header.section
        ._wrap.wrap
            router-link._logo-link(:to="{name: 'Home'}")
                img(src="./img/header-logo.svg", alt="World of job logo")._logo-pic
            ._auth
              app-loading(v-if="loading")
              router-link(:to="{name: 'Auth', params: { type: 'login' }}" v-if="!myUser.id")._auth-link Авторизация
                icon(name="user" component="app-header")._auth-icon
              button.btn.-blue.-small._create-order(v-if="!isFreelancer" @click="createOrder") Создать заказ
              app-user-menu(v-if="isAuth")
              
</template>

<script>
import appUserMenu from "@/components/app-common/app-user-menu/app-user-menu";

export default {
  name: "app-header",
  components: { appUserMenu },
  data() {
    return {
      loading: true
    };
  },
  methods: {
    createOrder() {
      if (!localStorage.token) {
        localStorage.redirect = "Create";
      }
      this.$router.push({ name: "Create" });
    }
  },
  mounted() {
    this.loading = false;
  }
};
</script>

<style src="./app-header.scss" lang="scss" scoped></style>
