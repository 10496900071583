<template lang="pug">
  .page-container
    policy
</template>

<script>
import policy from "@/components/policy/content/content.vue";

export default {
  name: "Policy",
  components: {
    policy
  }
};
</script>
