<template lang="pug">
.profile-balance
  ._wrap
    ._header
        ._balance Счёт на сайте: {{ user.balance }} ₽
        ._ctrl
          app-button(size="small" @click.native="balanceModalStatus = true")._ctrl-btn Пополнить
          app-button(size="small")._ctrl-btn Вывести
    ._content
      ._title История транзакций    
      ._table(v-if="transactions.length")
        ._table-thead
          ._table-row
              ._table-cell Дата
              ._table-cell Операция
              ._table-cell Статус
              ._table-cell Сумма
        ._table-tbody
            ._table-row(v-for="transaction in transactions" :key="transaction.id")
              ._table-cell {{ formatDate(transaction.transaction_date) }}
              ._table-cell {{ transaction.name }}
              ._table-cell {{ transactionStatus(transaction.transaction_status, transaction.type) }}
              ._table-cell(:class="getSummStyles(transaction.type, transaction.transaction_status)") {{ transaction.summ }} ₽
      app-info(v-else) У вас пока нет транзакций
  
  
  app-modal(:visible="balanceModalStatus" @close="balanceModalStatus = false")
    ._deposit-modal(slot="content")
      template(v-if="!depositError")
        app-loading(v-if="isDepositLoading" text="Загрузка платёжных данных")
        ._deposit-modal-title Пополнение счёта
        ._deposit-modal-content
          app-input(type="text" :numbersOnly="true" :model.sync="summ" :value="summ" unit="₽" label="Введите сумму" placeholder="1000" :validator="$v.summ.$error" error="Введите сумму от 10 до 100 000 рублей")
          app-button._deposit-modal-btn(@click.native="deposit") Пополнить
      app-info(type="error" v-else) {{ depositError }}    
</template>

<script>
import { required, between } from "vuelidate/lib/validators";
import TransactionsModel from "@/models/transactions.model";
import transactionStatus from "@/utils/transaction.status";

const TRANSACTIONS = new TransactionsModel();

export default {
  name: "profile-balance",
  data() {
    return {
      transactions: [],
      award: 0,
      balanceModalStatus: false,
      summ: null,
      isDepositLoading: false,
      depositError: null
    };
  },
  validations: {
    summ: {
      required,
      between: between(10, 100000)
    }
  },
  computed: {
    user: state => state.$store.getters["user/info"]
  },
  async created() {
    await this.getEvents();
  },
  methods: {
    transactionStatus,
    getSummStyles(type, status) {
      return {
        in: type === "in" && status === "succeeded",
        out: type === "out" && status === "succeeded"
      };
    },
    async deposit() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      this.isDepositLoading = true;
      await TRANSACTIONS.create(this.summ)
        .then(r => {
          window.location = r.data.payment.confirmation.confirmation_url;
        })
        .catch(() => {
          this.depositError = "Что-то пошло не так. Повторите попытку позже";
        });
    },
    async getEvents() {
      await TRANSACTIONS.get().then(r => {
        this.transactions = r.data.transactions;
      });
    },
    formatDate(date) {
      const jsDate = new Date(date);
      console.log(date);
      let day = jsDate.getDate(),
        month = jsDate.getMonth() + 1,
        year = jsDate.getFullYear(),
        hours = jsDate.getHours(),
        minutes = jsDate.getMinutes();

      if (day < 10) day = "0" + day;
      if (month < 10) month = "0" + month;
      if (year < 10) year = "0" + year;
      if (hours < 10) hours = "0" + hours;
      if (minutes < 10) minutes = "0" + minutes;

      return `${day}.${month}.${year} ${hours}:${minutes}`;
    }
  },
  watch: {
    balanceModalStatus() {
      this.depositError = null;
      this.summ = null;
      this.isDepositLoading = false;
      this.$v.$reset();
    }
  }
};
</script>

<style src="./profile-balance.scss" lang="scss"></style>
