const transactionStatus = function(status, type) {
  if (!status) return "";

  const statuses = {
    in: {
      pending: "Ожидание оплаты",
      waiting_for_capture: "Ожидание подтверждения платежа",
      succeeded: "Платёж зачислен",
      canceled: "Платёж отменён"
    },
    out: {
      pending: "Зарезервированы",
      waiting_for_capture: "Ожидание выполнение заказа",
      succeeded: "Платёж завершен",
      canceled: "Платёж отменён"
    }
  };

  return statuses[type][status];
};

export default transactionStatus;
