<template lang="pug">
.statistics.section
  ._wrap.wrap
    ._item(v-for="item in items" :key="item.id")
      icon(:name="item.icon" component="statistics")._icon
      ._group
        p._description(v-html="item.title")
        ._count(v-html="item.count")
</template>

<script>
export default {
  name: "statistics",
  data() {
    return {
      items: [
        {
          id: 1,
          title: "Совершено сделок на сумму",
          icon: "user",
          count: 'Более 500 000 000 <span class="statistics__symbol">₽</span>'
        },
        {
          id: 2,
          title: "Максимальная сумма сделки",
          icon: "bill",
          count: '456 086 <span class="statistics__symbol">₽</span>'
        },
        {
          id: 3,
          title: "Совершено безопасных сделок",
          icon: "shield",
          count: "125 036 сделок"
        },
        {
          id: 4,
          title: "Довольных заказчиков и фрилансеров",
          icon: "croupier",
          count: "Более 100 000"
        }
      ]
    };
  }
};
</script>

<style lang="scss">
@import "statistics";
</style>
