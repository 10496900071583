<template lang="pug">
  .page-container
    about-site
    statistics
    faq(:questions="questions")
</template>

<script>
import AboutSite from "../components/about-page/about-site/about-site";
import Statistics from "../components/app-common/statistics/statistics";
import Faq from "../components/app-common/faq/faq";
export default {
  name: "About",
  components: { Faq, Statistics, AboutSite },
  data() {
    return {
      questions: null
    };
  },
  created() {
    this.$store.dispatch("GET_FAQ", "about").then(r => {
      this.questions = r.data.map(item => ({
        ...item,
        active: false
      }));
    });
  }
};
</script>
