<template lang="pug">
section.protect.section
  ._wrap.wrap
    ._content
      h2.title._title {{ title }}
      p._description {{ body }}
      button.btn.-blue-darken._btn Узнать больше о безопасной сделке
    img(src="./img/protect-shield.svg")._image
</template>

<script>
export default {
  name: "protect",
  props: {
    title: {
      require
    },
    body: {
      require
    }
  }
};
</script>

<style lang="scss">
@import "protect";
</style>
