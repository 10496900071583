<template lang="pug">
  section.content.section
    ._wrap.wrap
      h1.title._title Пользовательское соглашение
      ._body
        p._text Настоящая Политика конфиденциальности персональной информации (далее — Политика) действует в отношении всей информации, которую проект, находящийся в сети Интернет на домене kprod.info (далее — Сайт), может получить о любом посетителе сайта(далее — Пользователь) во время использования им.

        p._text Использование cайта означает безоговорочное согласие пользователя с настоящей политикой и указанными в ней условиями обработки его персональной информации; в случае несогласия с этими условиями пользователь должен воздержаться от использования сайта.

        strong._text.-strong  1. Персональная информация пользователей, которую получает и обрабатывает сайт

        p._text <span class="content__text content__text_strong">1.1.</span> В рамках настоящей политики под «персональной информацией пользователя» понимаются:

        p._text <span class="content__text content__text_strong">1.1.1.</span> Персональная информация, которую пользователь предоставляет о себе самостоятельно в процессе использования сайта.

        p._text <span class="content__text content__text_strong">1.1.2.</span> Данные, которые автоматически передаются сайтом в процессе его использования пользователем с помощью установленного на устройстве пользователя программного обеспечения, в том числе IP-адрес, информация из cookie, информация о браузере(или иной программе, с помощью которой осуществляется доступ к сайту), время доступа, адрес запрашиваемой страницы.

        p._text <span class="content__text content__text_strong">1.2.</span> Сайт в общем случае не проверяет достоверность персональной информации, предоставляемой пользователями, и не осуществляет контроль за их дееспособностью. Однако сайт исходит из того, что пользователь предоставляет достоверную и достаточную персональную информацию, и поддерживает эту информацию в актуальном состоянии.

        strong._text.-strong 2. Цели сбора и обработки персональной информации пользователей

        p._text <span class="content__text content__text_strong">2.1.</span> Сайт собирает и хранит те персональные данные, которые нужны для исполнения заявок/вопросов пользователя, а так же те данные, которые были предоставлены ПО пользователя автоматически.

        p._text <span class="content__text content__text_strong">2.2.</span> Персональную информацию пользователя сайт может использовать в следующих целях:

        p._text <span class="content__text content__text_strong">2.2.1.</span> Идентификация пользователя в рамках соглашений и договоров с сайтом;

        p._text <span class="content__text content__text_strong">2.2.2.</span> Связь с пользователем, в том числе направление уведомлений, запросов и информации, касающихся использования сайта, оказания услуг, а также обработка запросов и заявок от пользователя;

        p._text <span class="content__text content__text_strong">2.2.3.</span> Улучшение качества сайта, удобства его использования, разработка новых сервисов и услуг;

        p._text <span class="content__text content__text_strong">2.2.4.</span> Таргетирование рекламных материалов;

        strong._text.-strong 3. Условия обработки персональной информации пользователя и её передачи третьим лицам

        p._text <span class="content__text content__text_strong">3.1.</span> Сайт хранит персональную информацию пользователей в соответствии с внутренними регламентами конкретных сервисов.

        p._text <span class="content__text content__text_strong">3.2.</span> В отношении персональной информации пользователя сохраняется ее конфиденциальность, кроме случаев добровольного предоставления пользователем информации о себе для общего доступа неограниченному кругу лиц.

        p._text <span class="content__text content__text_strong">3.3.</span> Сайт вправе передать персональную информацию пользователя третьим лицам в следующих случаях:

        p._text <span class="content__text content__text_strong">3.3.1.</span> Пользователь выразил свое согласие на такие действия;

        p._text <span class="content__text content__text_strong">3.3.2.</span> Передача необходима в рамках оказания услуги пользователю с привлечением третьих лиц;

        p._text <span class="content__text content__text_strong">3.3.3.</span> Передача предусмотрена российским или иным применимым законодательством в рамках установленной законодательством процедуры;

        p._text <span class="content__text content__text_strong">3.3.4.</span> Такая передача происходит в рамках продажи или иной передачи бизнеса (полностью или в части), при этом к приобретателю переходят все обязательства по соблюдению условий настоящей политики применительно к полученной им персональной информации;

        p._text <span class="content__text content__text_strong">3.3.5.</span> В целях обеспечения возможности защиты прав и законных интересов сайта.

        p._text <span class="content__text content__text_strong">3.4.</span> При обработке персональных данных пользователей сайт руководствуется Федеральным законом РФ «О персональных данных».

        strong._text.-strong 4. Изменение пользователем персональной информации

        p._text Пользователь может в любой момент изменить (обновить, дополнить, удалить) предоставленную им персональную информацию или её часть, написав письмо на адрес электронной почты confidential@kprod.agency со соответствующим требованием.

        strong._text.-strong 5. Меры, применяемые для защиты персональной информации пользователей

        p._text Сайт принимает необходимые и достаточные организационные и технические меры для защиты персональной информации пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с ней третьих лиц.

        strong._text.-strong 6. Изменение Политики конфиденциальности

        p._text <span class="content__text content__text_strong">6.1.</span> Сайт имеет право вносить изменения в настоящую политику без предварительного уведомления пользователя. При внесении изменений в актуальной редакции указывается дата последнего обновления. Новая редакция политики вступает в силу с момента ее размещения, если иное не предусмотрено новой редакцией политики. Действующая редакция всегда находится на странице по адресу https://kprod.agency/confidential.html

        p._text <span class="content__text content__text_strong">6.2.</span> К настоящей политике и отношениям между пользователем и сайтом подлежит применению право Российской Федерации.

        strong._text.-strong 7. Обратная связь. Вопросы и предложения

        p._text Все предложения или вопросы по поводу настоящей политики следует сообщать в службу поддержки пользователей сайта на адрес электронной почты confidential@kprod.agency
</template>
<script>
export default {
  name: "content"
};
</script>
<style lang="scss">
.content {
  background: #fff;
  padding: 60px 0 80px;

  @media (max-width: 767px) {
    padding: 30px 0;
  }

  &__wrap {
    flex-direction: column;
  }

  &__title {
    margin-bottom: 30px;
  }

  &__text {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 18px;

    &_strong {
      display: inline-block;
      font-weight: 500;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
