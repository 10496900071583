<template lang="pug">
  .page-container
    project-full
</template>

<script>
import projectFull from "@/components/projects/project-full/project-full";

export default {
  components: { projectFull }
};
</script>
