<template lang="pug">
section.best.section
  ._bg
  ._wrap.wrap
    h2.title._title Лучшие фрилансеры
    ._list
      ._item(v-for="i in [1, 2, 3, 4, 5]" :key="i")
        ._about
          span._about-num {{ i }}
          img(src="./img/best-thumb.jpg")._about-avatar
          ._about-info
            ._about-name Андрей Колесов
            span._about-job Сайты под ключ
  
        ._success
          ._success-num 520
          ._success-description успешных проектов
  
        ._rating
          ._rating-average
            | Средняя оценка: 5
          ._stars
            icon(name="star" component="common")._star
            icon(name="star" component="common")._star
            icon(name="star" component="common")._star
            icon(name="star" component="common")._star
            icon(name="star" component="common")._star
    router-link(:to="{name: 'Freelancers'}" tag="button").btn.-blue-darken._btn Все фрилансеры
</template>

<script>
export default {
  name: "best-freelancers"
};
</script>

<style lang="scss">
@import "best";
</style>
