<template lang="pug">
form.form(@submit.prevent="reset")
  template(v-if="!status")
    ._group
      label(for="email")._label Ваш email
      ._group-error(v-if="$v.email.$anyError") Введите верный email
      ._group-error(v-if="error") {{ error }}
      input._input#email(type="text" v-model="email" placeholder="")
  
    button(type="submit").btn.-blue-darken._send Восстановить пароль
    ._footer
      span._message Вспомнили пароль?
      router-link(:to="{name: 'Auth', params: { type: 'login' }}" tag="a")._link Авторизация
  .success(v-else)
    icon(name="checkmark" component="forgot-form")._ico
    p._info
      | На почту
      span._link {{ email }}
      | выслана инструкция по восстановлению пароля
</template>

<script>
import { email, required } from "vuelidate/lib/validators";

export default {
  name: "register-form",
  data() {
    return {
      email: null,
      error: null,
      status: false
    };
  },
  validations: {
    email: {
      required,
      email
    }
  },
  methods: {
    async reset() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      this.$store
        .dispatch("auth/RESET_PASSWORD", this.email)
        .then(r => {
          const user = r.data.user;
          if (user.length) {
            this.status = true;
          } else {
            this.error = "Указанный email не найден в базе.";
          }
        })
        .catch(() => {});
    }
  }
};
</script>

<style src="./form.scss" lang="scss" scoped></style>
