<template lang="pug">
    .user-avatar(:class="modificator")
      img(:src="avatar" v-if="avatar")._img
      icon(name="user" component="user-avatar" v-else)._no-avatar-icon
</template>

<script>
export default {
  props: {
    avatar: {
      required: false,
      type: String,
      default: ""
    },
    noAvatarBg: {
      required: false,
      default: ""
    }
  },
  computed: {
    modificator: state => [
      {
        "user-avatar_no-avatar": !state.avatar
      },
      {
        "user-avatar_no-avatar_blue": state.noAvatarBg === "blue"
      }
    ]
  }
};
</script>

<style lang="scss" scoped>
@import "user-avatar";
</style>
