<template lang="pug">
.chat-preview(v-if="user")
    ._user-avatar
      app-user-avatar(:avatar="user.avatar")
    ._info
        ._user-name {{ user.name }}
        ._project-name(v-if="project") {{ project.title }}
</template>

<script>
import UsersModel from "@/models/users.model";
import ProjectsModel from "@/models/projects.model";

const USERS = new UsersModel();
const PROJECTS = new ProjectsModel();

export default {
  name: "chat-preview",
  props: {
    userId: {
      type: Number,
      default: 0,
      required: true
    },
    projectId: {
      type: Number,
      default: 0,
      required: true
    }
  },
  data() {
    return {
      user: null,
      project: null
    };
  },
  async mounted() {
    await this.getUser();
    await this.getProject();
  },
  methods: {
    async getUser() {
      await USERS.getOneById(this.userId).then(r => {
        this.user = r.data.user;
      });
    },
    async getProject() {
      await PROJECTS.getOneById(this.projectId).then(r => {
        console.log(r.data.project);
        this.project = r.data.project;
      });
    }
  }
};
</script>

<style src="./chat-preview.scss" lang="scss"></style>
