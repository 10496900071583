<template lang="pug">
section.banner.section
  ._bg
  ._wrap.wrap
    h1.title._title Помогаем заказчикам и исполнителям находить друг друга и совершать безопасные сделки
    ._actions
      router-link(:to="{name: 'Create'}" tag="button" type="button" v-if="!isFreelancer").btn.-blue-darken._btn Создать заказ
      router-link(:to="{name: 'Projects'}" tag="a")._link Найти работу
</template>

<script>
export default {
  name: "banner"
};
</script>

<style src="./banner.scss" lang="scss" scoped></style>
