import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "../store";
import middlewarePipeline from "./middlewarePipeline";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes
});

router.beforeEach((to, from, next) => {
  if (to.query.invite) {
    console.log(true);
    localStorage.referral = +to.query.invite;
    to.query.invite = "";
  }

  if (!to.meta.middleware) {
    window.scrollTo(0, 0);
    return next();
  }

  const middleware = to.meta.middleware;
  const context = {
    to,
    from,
    next,
    store
  };

  console.log("ctx", context);
  return middleware[0]({
    ...context,
    nextMiddleware: middlewarePipeline(context, middleware, 1)
  });
});

export default router;
