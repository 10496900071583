<template lang="pug">
    .app-modal(v-if="visible")
        ._dialog(@click.self="close")
            ._content(@click.self="close")
                slot(name="content")
</template>

<script>
export default {
  name: "app-modal",
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close() {
      this.$emit("close");
    }
  },
  created() {
    document.querySelector("body").classList.add("hidden");
  },
  destroy() {
    document.querySelector("body").classList.remove("hidden");
  }
};
</script>

<style src="./app-modal.scss" lang="scss" scoped></style>
