<template lang="pug">
  .page-container.section
    .wrap.-column
      profile-nav(v-if="myProfile")
      component(:is="profileType")
</template>

<script>
import profileNav from "@/components/profile/profile-nav/profile-nav";
import profileMain from "@/components/profile/profile-main/profile-main";
import profileProjects from "@/components/profile/profile-projects/profile-projects";
import profileReferralProgram from "@/components/profile/profile-referral-program/profile-referral-program";
import profileBalance from "@/components/profile/profile-balance/profile-balance";

export default {
  name: "Profile",
  components: {
    profileNav,
    profileMain,
    profileProjects,
    profileReferralProgram,
    profileBalance
  },
  data() {
    return {
      profilePageTypes: [
        "",
        "main",
        "projects",
        "vacancy",
        "referral-program",
        "balance",
        "settings"
      ]
    };
  },
  computed: {
    routeType: state => state.$route.params.type,
    profileType: state => {
      const type = state.routeType ? state.routeType : "main";
      return state.profilePageTypes.indexOf(type) !== -1
        ? `profile-${type}`
        : `profile-main`;
    },
    myUserId: state => state.$store.getters["user/id"],
    routeUserId: state => state.$route.params.id,
    myProfile: state => state.myUserId == state.routeUserId
  },
  created() {
    this.checkProfileType();
  },
  methods: {
    checkProfileType() {
      if (this.profilePageTypes.indexOf(this.routeType) < 0) {
        this.$router
          .replace({ name: "Profile", params: { type: "main" } })
          .catch(() => {});
      }
    }
  },
  watch: {
    routeType() {
      this.checkProfileType();
    }
  }
};
</script>
