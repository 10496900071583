<template lang="pug">
  .page-container
    in-the-works
</template>

<script>
import InTheWorks from "../components/app-common/in-the-works/in-the-works";
export default {
  name: "Customer",
  components: { InTheWorks }
};
</script>
