<template lang="pug">
.project.-filter(v-if="!deleted")
  ._header
    ._info
      router-link(:to="{name: 'Project', params: { id: project.id }}" tag="a")._title {{ project.title }} {{ project.id }}
      span._category(v-if="category") {{ category.name }}
    ._statistics
      ._price Стоимость:
        span._total {{ project.budget }} ₽
      ._deadline Срок:
        span._day {{ project.deadline }} {{ project.deadline_type }}
  ._body
    p._description {{ project.description }}  {{ project.status }}
  ._footer(:class="{full: type === 'full'}")
    ._footer-group(v-if="category && type !== 'full'")
      router-link(:to="{name: 'Project', params: { id: project.id }}" tag="button" v-if="project.status !== 101").btn.-blue-darken._btn.-sm Подробнее
      button(v-if="user.id === project.customer && project.status === 100" @click="closeProject").btn.-red._btn.-sm Завершить заказ
      ._status(v-if="projectStatus") {{ projectStatus }}
    ._author(v-else)
      ._author-title Заказчик:
      router-link(:to="{name: 'Profile', params: { id: project.customer }}" tag="a" v-if="user.id")._author-link {{ author.name }}
      ._author-link(v-tippy='{ arrow : true, arrowType : "round" }' content="Авторизуйтесь, чтобы увидеть заказчика" v-else) скрыт
    ._date {{ publishDate }}
</template>

<script>
import moment from "moment";
import UsersModel from "@/models/users.model";
import projectsModel from "@/models/projects.model";
const USERS = new UsersModel();
const PROJECTS = new projectsModel();

moment.locale("ru");

export default {
  name: "project",
  data() {
    return {
      projectsCount: 0,
      author: {},
      deleted: false
    };
  },
  props: {
    project: {
      required: true,
      type: Object,
      default: () => ({})
    },
    type: {
      required: false,
      type: String,
      default: ""
    }
  },
  computed: {
    user: state => state.$store.getters["user/info"],
    publishDate: state =>
      moment(state.project.date).format("DD.MM.YYYY в H:ss"),
    category: state => {
      return state.$store.getters.categories.find(
        category => category.id === state.project.category
      );
    },
    projectStatus: state =>
      state.project.status === 101 ? "Вы завершили этот заказ" : false
  },
  async created() {
    if (this.type === "full" && this.project.customer !== undefined) {
      await USERS.getOneById(this.project.customer).then(r => {
        this.author = r.data.user;
      });
    }
  },
  methods: {
    async closeProject() {
      await PROJECTS.setStatus(this.project.id, 101, "")
        .then(r => {
          if (r.data.code === 200) {
            this.deleted = true;
            this.$toasts.success("Заказ завершён!", {
              type: "success"
            });
          }
        })
        .catch(e => {
          this.$toasts.push(e.data.message);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "project";
</style>
