<template lang="pug">
  .app-loading(:class="theme")
    span(v-if="text") {{ text }}
</template>

<script>
export default {
  name: "app-loading",
  props: {
    theme: {
      default: ""
    },
    text: {
      required: false,
      default: ""
    }
  }
};
</script>

<style lang="scss">
.app-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
  font-size: 12px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #f3f6fa;
  border-radius: 5px;
  border: 1px solid $light-accent;
  z-index: 1000;

  &:after {
    content: "";
    position: absolute;
    top: calc(50% - 8px);
    left: calc(50% - 8px);
    width: 16px;
    height: 16px;
    border-radius: 20px;
    border: 2px solid $blue;
    border-bottom-color: transparent;
    animation: rotateLoading 3s infinite;
  }

  &_darken {
  }
}

@keyframes rotateLoading {
  from {
    transform: rotateZ(0);
  }
  to {
    transform: rotateZ(360deg);
  }
}
</style>
