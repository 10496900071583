<template lang="pug">
.profile-referral
  ._wrap
    ._header
        ._title Ваши рефералы
          sup._total {{ referrals.length }}
        ._award Ваше вознаграждение:
          span._percent {{ user.referralAward }}%
    ._link-box
      ._link-title Ваша реферальная ссылка:
      ._link https://worldofjob.ru?invite={{ user.id }}
    ._table(v-if="referrals.length")
      ._table-thead
        ._table-row
            ._table-cell Дата регистрации
            ._table-cell Реферал
            ._table-cell Вознагрождение
      ._table-tbody
          ._table-row(v-for="referral in referrals" :key="referral.id")
            ._table-cell {{ formatDate(referral.reg_date) }}
            ._table-cell {{ referral.name }} {{ referral.last_name }}
            ._table-cell {{ referral.award ? referral.award : 0 }} ₽
    app-info(v-else) У вас пока нет рефералов         
    //._footer
    //    app-button(size="over-text") Показать еще        

</template>

<script>
import usersModel from "@/models/users.model";

const USERS = new usersModel();

export default {
  name: "profile-referral-program",
  data() {
    return {
      referrals: [],
      award: 0
    };
  },
  computed: {
    user: state => state.$store.getters["user/info"]
  },
  async created() {
    await this.getReferrals();
  },
  methods: {
    async getReferrals() {
      await USERS.getReferrals().then(r => {
        this.referrals = r.data.referrals;
      });
    },
    formatDate(date) {
      const jsDate = new Date(date);
      let day = jsDate.getDate(),
        month = jsDate.getMonth() + 1,
        year = jsDate.getFullYear();

      if (day < 10) day = "0" + day;
      if (month < 10) month = "0" + month;
      if (year < 10) year = "0" + year;

      return `${day}.${month}.${year}`;
    }
  }
};
</script>

<style src="./profile-referral-program.scss" lang="scss"></style>
